import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '@models/user';
import { LocalizationProvider } from '@providers/localization.provider';
import { QueryParamsService } from '@services/query-params.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/services/xrm/configuration.service';
import { ChoicesPositions } from '../../../../../constants/choice';
import { SpecialData } from '../../../../../providers/special-data.provider';
import { MainDataProvider } from '../../../../../providers/xrm/main-data.provider';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { PopupModalsService } from '../../../../../services/popup-modals.service';
import { BookingTransactionsService } from '../../../../../services/xrm/booking-transactions.service';
import { UserDataService } from '../../../../../services/xrm/user-data.service';
import { environment } from './../../../../../../environments/environment';
import { OrderData } from './../../../../../providers/xrm/order-data.provider';
import { BookingDetailsService } from './../../../../../services/booking-details.service';
import { PostMessageService } from './../../../../../services/post-message.service';
import { InitConfigDataService } from '@services/init-config-data.service';
import { getGoogleCookieId } from './../../../../../helpers/global-functions';
import { ConfigurationData } from '@providers/configuration-data.provider';
import { LoaderService } from '@services/loader.service';
import { UserData } from '@providers/user-data.provider';
import { AdditionalService } from '@services/additional.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    //LocalizedTexts
    public logoutButtonLabelText = '';
    public openLoginFormLabelText = '';
    public noAccountLabel1Text = '';
    public noAccountLabel2Text = '';
    public loginButtonLabelText = '';
    public selectLanguageTitle = '';

    /**
     * Component members for use
     */
    public phone = '';
    public formatted_phone = '';
    public hidePhone = false;
    public hideAccountsMenu = false;
    public currentPosition;
    public isUserLogged = false;
    public waUserMenuConfig: any = null;
    public userData: any = null;
    public origin = '';
    public logoUrl = '';
    public srcSetValue: string = null;
    public isLogoFromFileServer: boolean = false;
    public isLogoInSvgFormat: boolean = false;
    public avatarImgUrl = '';
    public noPrice = false;
    public isInitStep = true;
    public showLanguageSelectorOnCurrentStep = true;
    public brandName = '';
    public integrate = environment.integrate;
    public bookingType = 'normal';
    public hasMultipleLanguages: boolean = false;
    public availableLanguages: Array<any> = null;
    public showProgressBar: Boolean = true;

    public disableAccounts = false;
    public isAnonymSession: Boolean = false;
    public hideLoginButton = false;
    public isLocalhost: boolean = false;

    public toggleLoginMenu: boolean = false;
    public attrTarget = '_blank';
    public currentLanguage: string = '';
    public showLanguagesList = false;

    /**
     * Subscriptions
     */
    private subscriptions: Subscription[] = [];
    /**
     * Dynamic members
     */
    public bookingProgress: number = null;
    public stepsProgress = [
        {
            step: 1,
            status: 'active',
            dateE2e: 'progress-steps-service-details',
            title: {
                priceTitle: this._localizationProvider.getLocalizedText('serviceDetails'),
                noPriceTitle: this._localizationProvider.getLocalizedText('serviceDetails'),
            },
        },
        {
            step: 2,
            status: 'inactive',
            dateE2e: 'progress-steps-prices-&-schedule',
            title: {
                priceTitle: this._localizationProvider.getLocalizedText('stepsProgressPriceTitle1'),
                noPriceTitle: this._localizationProvider.getLocalizedText('stepsProgressNoPriceTitle1'),
            },
        },
        {
            step: 3,
            status: 'inactive',
            dateE2e: 'progress-steps-contact-details',
            title: {
                priceTitle: this._localizationProvider.getLocalizedText('contactDetailsLabel'),
                noPriceTitle: this._localizationProvider.getLocalizedText('contactDetailsLabel'),
            },
        },
        {
            step: 4,
            status: 'inactive',
            dateE2e: 'progress-steps-complete-booking',
            title: {
                priceTitle: this._localizationProvider.getLocalizedText('completeBookingLabel'),
                noPriceTitle: '',
            },
        },
    ];
    // public backToSearch: Function = null;
    public handleMobileBack: Function = null;
    // public hasAddress = false;

    public showMobileBackFlag = true;
    public isReschedule = this._bookingTransactionsService.getTransactionType() === 'reschedule';
    public consiergeMenu: any = null;
    public gclid = '';

    public generalTexts: any = null;
    public isAccountMenuHidden = false;

    public documentDirection = '';

    constructor(
        public _navigationService: NavigationService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
        private popupModalsService: PopupModalsService,
        private _userDataService: UserDataService,
        private _userDataProvider: UserData,
        private _authService: AuthenticationService,
        private _bookingTransactionsService: BookingTransactionsService,
        private _specialDataProvider: SpecialData,
        private zone: NgZone,
        private router: Router,
        private _orderData: OrderData,
        private _configurationService: ConfigurationService,
        private _deviceDetectorService: DeviceDetectorService,
        private _queryParams: QueryParamsService,
        private _postMessageService: PostMessageService,
        private _initConfigDataService: InitConfigDataService,
        private _configurationProvider: ConfigurationData,
        private _loaderService: LoaderService,
        private _additionalService: AdditionalService
    ) {

        // set the default logo for the brand.
        this.logoUrl = this._mainDataProvider.obfEnv.additionalConfig.logos.brandLogo;
        this.brandName = this._mainDataProvider.obfEnv.additionalConfig.logos.brandName;
        // set the default avatar img
        this.avatarImgUrl = this._mainDataProvider.obfEnv.additionalConfig.logos.avatarLogo;

        this.isLocalhost = this._mainDataProvider.obfEnv.env === 'localhost' ? true : false;

        if (this.integrate) {
            // this is use only in integrate this why need to not be register in window object
            window._OBF = {
                loginPopup: (callback: Function, activeTab) => {
                    this.zone.runOutsideAngular(() => {
                        this.zone.run(() => {
                            this.popupModalsService.openLoginRegister({ callback, openTab: activeTab, closeOnOverlayClick: true });
                            // this.appComponent.triggerGlobalChangeDetection();
                        });
                    });
                },
                logout: (callback: Function) => {
                    this.zone.runOutsideAngular(() => {
                        this.zone.run(() => {
                            this._authService.logout().then(() => {
                                callback('success');
                            }).catch(() => {
                                callback('error logout');
                            });
                        });
                    });
                },
            };
        }
    }

    public setLocalizedTexts() {

        this.generalTexts = this._configurationService.getConfigurationSettings()?.customer?.texts?.general;

        this.logoutButtonLabelText = this.generalTexts?.logoutButtonLabel ? this.generalTexts.logoutButtonLabel : this._localizationProvider.getLocalizedText('logoutButtonLabel');
        this.openLoginFormLabelText = this.generalTexts?.openLoginFormLabel ? this.generalTexts.openLoginFormLabel : this._localizationProvider.getLocalizedText('openLoginFormLabel');
        this.noAccountLabel1Text = this.generalTexts?.noAccountLabel1 ? this.generalTexts.noAccountLabel1 : this._localizationProvider.getLocalizedText('noAccountLabel1');
        this.noAccountLabel2Text = this.generalTexts?.noAccountLabel2 ? this.generalTexts.noAccountLabel2 : this._localizationProvider.getLocalizedText('noAccountLabel2');
        this.loginButtonLabelText = this.generalTexts?.loginButtonLabel ? this.generalTexts.loginButtonLabel : this._localizationProvider.getLocalizedText('loginButtonLabel');
        this.selectLanguageTitle = this.generalTexts?.selectLanguageTitle ? this.generalTexts.selectLanguageTitle : 'Select language';

        this.setProgressData();
    }

    public ngOnInit() {

        this.subscriptions.push(
            this._additionalService.documentDirection.subscribe(
                (direction) => {
                    this.documentDirection = direction;
                }
            )
        )

        const bookConfiguration = this._configurationService.getConfigurationSettings()?.book;

        if (bookConfiguration?.settings?.hide_accounts_menu) {
            this.isAccountMenuHidden = true;
        }

        this.initialize();
    }

    private setProgressData() {
        if (this.stepsProgress?.length && this.stepsProgress[0]) this.stepsProgress[0].title.priceTitle = this._localizationProvider.getLocalizedText('serviceDetails');
        if (this.stepsProgress?.length && this.stepsProgress[0]) this.stepsProgress[0].title.noPriceTitle = this._localizationProvider.getLocalizedText('serviceDetails');
        if (this.stepsProgress?.length && this.stepsProgress[1]) this.stepsProgress[1].title.priceTitle = this._localizationProvider.getLocalizedText('stepsProgressPriceTitle1');
        if (this.stepsProgress?.length && this.stepsProgress[1]) this.stepsProgress[1].title.noPriceTitle = this._localizationProvider.getLocalizedText('stepsProgressNoPriceTitle1');
        if (this.stepsProgress?.length && this.stepsProgress[2]) this.stepsProgress[2].title.priceTitle = this._localizationProvider.getLocalizedText('contactDetailsLabel');
        if (this.stepsProgress?.length && this.stepsProgress[2]) this.stepsProgress[2].title.noPriceTitle = this._localizationProvider.getLocalizedText('contactDetailsLabel');
        if (this.stepsProgress?.length && this.stepsProgress[3]) this.stepsProgress[3].title.priceTitle = this._localizationProvider.getLocalizedText('completeBookingLabel');
    }

    public ngOnDestroy(): void {
        // Unsubscribe for all of subscriptions made
        this.subscriptions.map((subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Handle click on href:tel
     */
    public clickToCall(phoneNumber) {
        if (phoneNumber && (this._deviceDetectorService.isMobile || this._deviceDetectorService.isTablet)) {
            this._postMessageService.sendState('click-to-call', {
                data: { href: 'tel:' + phoneNumber }
            })
        }
    }

    private appendIconStyle(iconHref: string) {
        if (!document.querySelector(`link[href="${iconHref}"]`)) {
            const iconStyle = document.createElement('link');
            iconStyle.rel = 'stylesheet';
            iconStyle.href = iconHref;
            document.head.appendChild(iconStyle);
        }
    }

    /**
     * Initialize component
     */
    private async initialize(): Promise<void> {

        // Fetch menu with current language on header init
        if (!this.isAccountMenuHidden && this._userDataService.isUserLogged()) {
            // const loader = this._loaderService.showLoader();

            this.waUserMenuConfig = this._userDataProvider.getAccountsMenuConfig();

            this._userDataService.fetchAccountsMenuConfig()
                .then(
                    (response) => {
                        // this.waUserMenuConfig = response;

                        // this._loaderService.hideLoader(loader);
                    },
                    (error) => {
                        //
                        // this._loaderService.hideLoader(loader);
                    }
                );
        }

        // * Get parent cookies.
        const parentCookies =
        await this._initConfigDataService
            .getParentCookies()
            .catch((err) => {
                 //
            });
        
        // Set gclid if exist in parent cookies
        if (parentCookies?.hasOwnProperty('_gcl_aw')) {
            this.gclid = getGoogleCookieId(parentCookies['_gcl_aw']);
        }

        // set attribute target to '_parent' to prevent bug on safari with tapping
        if (this._deviceDetectorService.browser.toLowerCase() === 'chrome' && this._deviceDetectorService.os.toLowerCase() === 'ios') {
            this.attrTarget = '';
        } else if (this._deviceDetectorService.browser.toLowerCase() === 'safari') {
            this.attrTarget = '_parent';
        }

        this.subscriptions.push(
            this._configurationProvider.configurationSettingsBS.subscribe(
                (data) => {
                    let direction = 'ltr';
                    let currentLanguageData = null;
                    const currentLanguage: string = this._localizationProvider.getCurrentLanguage();

                    this.setLocalizedTexts();
                    this._userDataService.buildAccountsMenu();

                    if (data?.application?.settings?.languages?.length) {
                        currentLanguageData = data.application.settings.languages.find(
                            (language) => {
                                return language.code === currentLanguage
                            }
                        )
                    }

                    direction = currentLanguageData?.text_direction ? currentLanguageData.text_direction : 'ltr';

                    // Backup - handle if language is arabic
                    // if (currentLanguage === 'ar') {
                    //     direction = 'rtl';
                    // }

                    this._additionalService.setDocumentDirection(direction);
                }
            )
        )

        this.subscriptions.push(
            this._userDataProvider.accountsMenuConfig.subscribe(
                (accountMenu) => {
                    this.waUserMenuConfig = accountMenu;

                    // Set Avatar Url
                    if (this.waUserMenuConfig && this.waUserMenuConfig.avatarUrl) {
                        this.avatarImgUrl = this.waUserMenuConfig.avatarUrl;
                    }

                    // Set accounts font for menu items icons
                    if (this.waUserMenuConfig?.assets?.styleLink) {
                        this.appendIconStyle(this.waUserMenuConfig.assets.styleLink);
                    }
                }
            )
        )

        // this.subscriptions.push(
        //     this._userDataProvider.getSubscription().subscribe(
        //         (userData) => {
        //             this._userDataService.buildAccountsMenu();
        //         }
        //     )
        // )

        this.subscriptions.push(
            this._navigationService.successNavigation.subscribe((data) => {
                // Validate language selector visibility depends on current step
                const currentStep = this._navigationService.getCurrentStep();
                const isNoCoverage = currentStep.activeChild && currentStep.activeChild.position === ChoicesPositions.NoCoverage;
                this.showLanguageSelectorOnCurrentStep = currentStep.index < this._navigationService.getPositionData(ChoicesPositions.OnSummary).index && !isNoCoverage;
            })
        );

        this.subscriptions.push(
            this._localizationProvider.activeLanguage.subscribe(
                (language) => {
                    this.currentLanguage = language;
                    if (!this.isAccountMenuHidden && this.isUserLogged) {

                        this._userDataService.fetchAccountsMenuConfig()
                            .then(
                                (response) => {
                                },
                                (error) => {
                                }
                            );
                    }
                }
            )
        );

        this.subscriptions.push(this._orderData.noPrice.subscribe((data) => {
            this.noPrice = data;
        }));

        // Listen for user login
        this.subscriptions.push(this._userDataService.getUserDataSubscription().subscribe((data: any) => {
            
            this.waUserMenuConfig = this._userDataService.getAccountsMenuConfig();
            this.userData = data;

            if (this.waUserMenuConfig && this.waUserMenuConfig.avatarUrl) {
                this.avatarImgUrl = this.waUserMenuConfig.avatarUrl;
            }

            // if (data?.avatar?.url) {
            //     this.avatarImgUrl = data.avatar.url;
            // }

            if (this.waUserMenuConfig?.assets?.styleLink) {
                this.appendIconStyle(this.waUserMenuConfig.assets.styleLink);
            }

            this.isUserLogged = this._userDataService.isUserLogged();

            this.isAnonymSession = this._userDataService.isAnonymSession();
        }));

        this.subscriptions.push(this._mainDataProvider.getResourceObserverObfOptions().subscribe((data) => {

            const obfOptions: any = data;
            const fileServer = 'files.serviceos.com';
            const queryParams = this._queryParams.getQueryParams();
            const customerConfiguration = this._configurationService.getConfigurationSettings()?.customer;
            const bookConfiguration = this._configurationService.getConfigurationSettings()?.book;
            
            this.hidePhone = queryParams.hide_phone || obfOptions.hide_phone || bookConfiguration?.settings?.hide_phone;
            this.hideAccountsMenu = bookConfiguration?.settings?.hide_accounts_menu;

            this.phone = obfOptions.phone;
            this.origin = obfOptions.main_url;
            this.formatted_phone = (this.phone === undefined || this.phone === '' || this.phone == null) ? '' : this.phone;

            if (this.formatted_phone?.charAt(0) === '0') {
                this.formatted_phone = this.formatted_phone.substr(1);
            }

            const countryCode = this._configurationService.getConfigurationSettings().country_code;

            if (countryCode) {
                this.formatted_phone = '+' + countryCode + this.formatted_phone.replace(/\s/g, '');
            } else if (this._mainDataProvider.obfEnv.defaultPhoneCode) {
                this.formatted_phone = '+' + this._mainDataProvider.obfEnv.defaultPhoneCode + this.formatted_phone.replace(/\s/g, '');
            } else if (customerConfiguration?.settings?.dialing_code) {
                this.formatted_phone = customerConfiguration?.settings?.dialing_code + this.formatted_phone.replace(/\s/g, '');
            }

            // Set logo url if there's one in obfOptions
            if (obfOptions.logo_url) {
                this.logoUrl = obfOptions.logo_url;
            } 

            this.isLogoFromFileServer = this.logoUrl ? this.logoUrl.indexOf(fileServer) === -1 ? false : true : false;
            this.isLogoInSvgFormat = this.logoUrl ? this.logoUrl.indexOf('.svg') === -1 ? false : true : false;

            if (this.isLogoFromFileServer && !this.isLogoInSvgFormat) this.srcSetValue = `${this.logoUrl}/135 1x, ${this.logoUrl}/258 2x`;

            const consiergeObject: any = this._mainDataProvider.obfEnv.additionalConfig.concierge;

            this.consiergeMenu = consiergeObject && consiergeObject.email && consiergeObject.formattedPhoneNumber && consiergeObject.phoneNumber ? consiergeObject : null;
        }));

        /**
         * Dynamically update current methods/class members with passed ones
         */
        this.subscriptions.push(this._specialDataProvider.getHeaderConfigSubscription()
            .subscribe(async(config: any) => {
                if (config) {
                    const configKeys = Object.keys(config);

                    if (configKeys.length) {
                        for (let keyIndex = 0; keyIndex < configKeys.length; keyIndex++) {

                            if (configKeys[keyIndex] != 'stepsProgress') {

                                const key = configKeys[keyIndex];

                                if (this.hasOwnProperty(key)) {
                                    this[key] = config[key];
                                }
                            }

                        }

                        if (config.hasOwnProperty('stepsProgress') && Object.keys(config.stepsProgress).length) {
                            for (let key = 0; key < config.stepsProgress.length; key++) {
                                for (let keyStep = 0; keyStep < this.stepsProgress.length; keyStep++) {
                                    if (this.stepsProgress[keyStep].step === config.stepsProgress[key].step) {
                                        this.stepsProgress[keyStep].status = config.stepsProgress[key].newStatus;
                                    }
                                }
                            }
                        }
                    }
                }

                const authRestrictions = await this._userDataService.authRestrictions();
                
                this.hideLoginButton = authRestrictions === "not_required_auth" &&
                    (this._navigationService.getCurrentStep()?.position === ChoicesPositions.BeforeConfirmation ||
                        this._navigationService.getCurrentStep()?.position === ChoicesPositions.Confirmation)

                this.showMobileBackFlag = this.showMobileBack();

            }));

        this.subscriptions.push(this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {

                this.showMobileBackFlag = this.showMobileBack();
            }

            if (this._orderData.activeBooking.getValue()) {
                const configurationSettings: any = this._configurationService.getConfigurationSettings(),
                    membershipServiceId: number = configurationSettings && configurationSettings.membership_service_id ? configurationSettings.membership_service_id : null,
                    transaction: any = this._bookingTransactionsService.getTransaction(),
                    transactionServiceId: number = transaction && transaction.service ? transaction.service.id : null;

                this.showProgressBar = !membershipServiceId ? true : transactionServiceId && (membershipServiceId !== transactionServiceId);
            }
        }));

        const authRestrictions = await this._userDataService.authRestrictions();
        this.disableAccounts = authRestrictions === "anonym_auth";
        const obfOptions = this._mainDataProvider.getResourceObfOptions();

        // languages logic
        this.hasMultipleLanguages = this._localizationProvider.hasAvailableLanguages() && !(obfOptions && (document.referrer).indexOf(obfOptions.accounts_url) > -1);

        const profileLanguages: any = this._localizationProvider.getProfileLanguages();
        const activeLanguages: any = this._localizationProvider.getActiveLanguages();

        this.availableLanguages = activeLanguages ? activeLanguages.map(function(item) {
            // FOR DEBUG ONLY
            // if (item.code === 'en') {
            //     item.text_direction = 'rtl'
            // }
            return item;
          }) : [];
        

        if (this._orderData.activeBooking.getValue()) {
            const configurationSettings: any = this._configurationService.getConfigurationSettings(),
                membershipServiceId: number = configurationSettings && configurationSettings.membership_service_id ? configurationSettings.membership_service_id : null,
                transaction: any = this._bookingTransactionsService.getTransaction(),
                transactionServiceId: number = transaction && transaction.service ? transaction.service.id : null;

            this.showProgressBar = !membershipServiceId ? true : transactionServiceId && (membershipServiceId !== transactionServiceId);
        }
    }

    /**
     * Back button handler
     */
    public backToSite(): void {
        const obfOptions = this._mainDataProvider.getResourceObfOptions();
        if (obfOptions.main_url.includes(obfOptions.accounts_url)) {
            this.popupModalsService.openExitPopup(false, { goTo: 'Home' });

        } else {
            this.popupModalsService.openExitPopup(false, { goTo: 'SamePage' });
        }
    }

    public getNameInitials(name) {

        if (!name) return '';

        const fullName = name.split(' ');
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);

        return initials.toUpperCase();
    }

    /**
     * Fifth step handler method
     */
    public fifthStepBackHandler(): void {
        this._navigationService.navigateMe(ChoicesPositions.Init);
    }

    /**
     * Opens up modal for login and register
     */
    public openLoginRegisterModal(event, tab?): void {

        event.stopPropagation();

        this.popupModalsService.openLoginRegister({
            callback: () => {
            },
            closeOnOverlayClick: true,
            openTab: tab ? tab : null
        });
    }

    /**
     * Method which checks wether to show back button on mobile
     */
    public showMobileBack(): boolean {
        this.currentPosition = this._navigationService.getCurrentStep();

        if (this._orderData.getExitStepConfig()?.shouldDisableBackButtons) {
            return false;
        }

        const config = this._specialDataProvider.getHeaderConfig();
        if(config?.disableMobileBack) {
            return false;
        }

        if (this.currentPosition) {
            this.isInitStep = this.currentPosition.position === ChoicesPositions.Init;
            this.isReschedule = this._bookingTransactionsService.getTransactionType() === 'reschedule';
            this.bookingType = this._bookingTransactionsService.getTransactionType();
            this.stepsProgress[this.stepsProgress.length - 1].title.priceTitle = this.bookingType !== 'normal' && this.bookingType !== 'restore' ? 'Complete' : this._localizationProvider.getLocalizedText('completeBookingLabel');

            if (this.isReschedule) {
                const choices = this._bookingTransactionsService.getServiceChoices(ChoicesPositions.BeforeAvailability);

                if (this.currentPosition.position === ChoicesPositions.OnAvailability && choices.length) {
                    return true;
                } else if (this.currentPosition.position === ChoicesPositions.OnAvailability && choices.length === 0) {
                    return false;
                }

                if (this.currentPosition.position === ChoicesPositions.BeforeAvailability) {
                    return false;
                }

                return true;
            } if (this.bookingType === 'edit') {
                if (this.currentPosition.position === ChoicesPositions.Configurator && this.currentPosition.pathParams[0]) {
                    const choicesPositionConfigurator = this._bookingTransactionsService.getServiceChoices(ChoicesPositions.Configurator);
                    const activeChoiceId = this.currentPosition.pathParams[0];

                    const activeChoiceIndex = choicesPositionConfigurator.findIndex((el) => el.id === activeChoiceId);
                    if (activeChoiceIndex === 0) {
                        return false;
                    }

                    return true;
                }
            } else {

                if (this.currentPosition.position === ChoicesPositions.Init && this.currentPosition.activeChild && this.currentPosition.activeChild.position === ChoicesPositions.OnWelcome) {
                    return false;
                }

                return true;
            }
        }
    }

    /**
     * Logout user
     */
    public userLogout(): void {
        // Reset menu config
        this.waUserMenuConfig = null;
        this.userData = null;

        // End user session
        this._authService.logout()
            .then(
                (success: any) => { // Success
                    if (success) {
                        this.resetMenuData();
                        if (this._navigationService.getCurrentStep().position !== ChoicesPositions.Init) {
                            this._orderData.resetActiveBooking();
                            this._navigationService.navigateMe(ChoicesPositions.Init, { forceNavigate: true });
                        }
                    }
                },
                (response: any) => { // Error
                    // Logout error
                },
            );
    }

    private resetMenuData() {
        this.avatarImgUrl = this._mainDataProvider.obfEnv.additionalConfig.logos.avatarLogo;
    }

    public onLanguageSelect(selectedLanguage) {

        this._localizationProvider.setAppLanguage(selectedLanguage.code);

        // Restore transaction if change language after booking creation and before summary details
        const currentStep = this._navigationService.getCurrentStep();
        const obfOptions = this._mainDataProvider.getResourceObfOptions();
        const booking = this._orderData.activeBooking.getValue() ? this._orderData.activeBooking.getValue().get() : null;

        const restoreUrl = obfOptions.main_url + '?fs_screen=restored_booking&fs_payload[booking_transaction_id]=' + booking?.id;

        if (obfOptions?.main_url && currentStep.index >= this._navigationService.getPositionData(ChoicesPositions.Configurator).index) {
            // Show loading and reload
            const loader = this._loaderService.showLoader();
            window.location.href = restoreUrl;
        }

        
    }

    public getCurrentLanguageFlag() {
        let currentLanguageItem = this.availableLanguages && this.availableLanguages.filter((item) => item.code === this.currentLanguage);

        return currentLanguageItem && currentLanguageItem.length && currentLanguageItem[0].icon_url ? currentLanguageItem[0].icon_url : null;
    }

    public isMenuContainLogout(links) {
        return links.some( e => e.menuName === 'logout');
    }
}
