import { BehaviorSubject, Subscription } from 'rxjs';

import {
    Injectable,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentSelectModalComponent } from '../modules/shared/components/modals/payment-select-modal/payment-select-modal.component';
import {
    StandardPopupComponent,
    StandardPopupSettings,
} from '../modules/shared/components/modals/standard-popup/standard-popup.component';
import { UnitModalComponent } from '../modules/shared/components/modals/unit-modal/unit-modal.component';
import { LoaderService } from './loader.service';
import { PostMessageService } from './post-message.service';
import { DynamicIframeModalComponent } from '../modules/shared/components/modals/dynamic-iframe-modal/dynamic-iframe-modal.component';
import { EventTrackingService } from './tracking/event-tracking.service';
import { OrderData } from '@providers/order-data.provider';

@Injectable({
    providedIn: 'root',
})
export class PopupModalsService {
    public subscriptions: Subscription[] = [];

    public exitPopupBSubject: BehaviorSubject<{
        event: 'close' | 'open' | 'byclick' | string;
        data?: any;
    }>;
    public loader;
    public socialLogins: { [key: string]: string } = {};
    public loginLoaderOpen = false;
    public modalDataWhenLoader: any;

    public otherModals = false;

    public loginAndRegisterModalChange: BehaviorSubject<{
        action: string;
        data?: Function;
    }> = new BehaviorSubject(null);

    constructor(
        private modalService: NgbModal,
        private _loaderService: LoaderService,
        private _eventTrackingService: EventTrackingService,
        private postMessageService: PostMessageService,
        private _orderData: OrderData
    ) {

        this.exitPopupBSubject = new BehaviorSubject({
            event: 'close',
            data: {},
        });

        this.postMessageService.openExitSubject.subscribe((data) => {
            this.openExitPopup();
        });

        this._orderData.openExitModal.subscribe((data) => {
            if (data) {
                this.openExitPopup();
            }
        });
    }

    /**
     * Open login modal
     * @param modalData
     */
    public openLoginRegister(modalData: {
        callback?: Function;
        closeOnOverlayClick?: boolean;
        openTab?: 'login' | 'register' | 'resetPassword';
    }): void {

        const modalType = 'loginAndRegister';

        this._eventTrackingService.push({
            event: 'virtual-modal-view',
            virtualModalURL: '/booking-form/' + modalType + '/',
            virtualModalTitle: 'Booking Form Modal ' + modalType,
        });

        const modalConfig: any = {
            action: 'open',
            data: modalData,
        };

        this.loginAndRegisterModalChange.next(modalConfig);

        // Hide loader on popup init
        this._loaderService.hideLoader(this.loader);
    }

    public openExitPopup(
        byClick = false,
        data: { goTo: 'Home' | 'SamePage' | string } = { goTo: 'SamePage' },
    ) {
        if (byClick) {
            this.exitPopupBSubject.next({ event: 'byclick', data });
        } else {
            this.exitPopupBSubject.next({ event: 'open', data });
        }
    }

    /**
     * Open redirect modal
     * @param redirectData
     */
    public openRedirectPopup(redirectData): Promise<any> {
        const popupContentDef: StandardPopupSettings = {
            icon: 'redirect',
            title: redirectData.redirect_message_title,
            image: './calendar2.png ',
            description: redirectData.redirect_message,
            buttons: {
                xButton: {
                    action: 'close',
                },
                otherButtons: [
                    {
                        label: redirectData.OK_title,
                        action: 'redirect',
                        type: 'primary',
                        state: 'default',
                        size: 'normal',
                    },
                    {
                        label: redirectData.cancel_title,
                        action: 'close',
                        type: 'secondary',
                        state: 'default',
                        size: 'normal'
                    },
                ],
            },
        };

        const modalRef = this.modalService.open(StandardPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
        });
        modalRef.componentInstance.popupSettings = popupContentDef;

        return modalRef.result;
    }

    /**
     * Open info popup
     * @param {string} errorMessage
     */
    public standardInfoPopup(
        title: string,
        message: string,
        fullModalConfig?: StandardPopupSettings,
    ): Promise<any> {
        let popupContentDef: StandardPopupSettings = {
            icon: '',
            title: '',
            image: '',
            description: message,
            buttons: {
                xButton: {
                    action: 'close',
                },
                otherButtons: [
                    {
                        label: 'Ok',
                        action: 'close',
                        type: 'primary',
                        state: 'default',
                        size: 'normal',
                    },
                ],
            },
        };

        if (fullModalConfig) {
            popupContentDef = fullModalConfig;
        }

        const modalRef = this.modalService.open(StandardPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
        });
        modalRef.componentInstance.popupSettings = popupContentDef;
        return modalRef.result;
    }

    /**
     * Open pick unit modal
     * @param unit
     * @param option
     */
    public infoModalForPickUnit(
        unit,
        option: { onlyView: boolean } = { onlyView: false },
    ): any {
        const modalRef = this.modalService.open(UnitModalComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
        });

        modalRef.componentInstance.unit = unit;
        modalRef.componentInstance.onlyView = option.onlyView;
        modalRef.componentInstance.modalRef = modalRef;

        return modalRef.result;
    }

    /**
     * Open modal for payment selection
     * @param paymentsArray
     */
    public openPaymentSelectPopup(paymentsArray: any[]): any {
        const modalRef = this.modalService.open(PaymentSelectModalComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
        });

        modalRef.componentInstance.paymentsArray = paymentsArray;

        return modalRef.result;
    }

    /**
    * Open modal with iframe inside.
    */
    public openIframeModal(title: string, url: string): any {
        const modalRef = this.modalService.open(DynamicIframeModalComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
        });

        modalRef.componentInstance.config = {
            title: title,
            url: url
        };

        return modalRef.result;
    }

    /**
     * Check for open modals
     */
    public hasOpenModals(): boolean {
        return this.modalService.hasOpenModals();
    }

    /**
     * Force closing of all modals
     * @param action
     */
    public closeAllOpenModals(action: any): void {
        this.modalService.dismissAll(action);
    }
}
