import { transition } from '@angular/animations';
import { Injectable, Injector } from '@angular/core';
import { Category } from '@models/category';
import { DependencyMethod } from '@models/dependency-method';
import { ServerResponse } from '@models/server-response';
import { Service } from '@models/service';
import { LocalizationProvider } from '@providers/localization.provider';
import { SDKClientService } from 'lib/xrm-sdk/src/lib/Services/SDKClientService';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChoicesPositions } from '../../constants/choice';
import { ErrorHandlingService } from '../errors/error-handling.service';
import { ErrorReportingService } from '../errors/error-reporting.service';
import { LoaderService } from '../loader.service';
import { SDKQueryParams } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { SDKServicesDataService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKServicesDataService';
import { RequestConfig } from './../../models/request-config';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { ServiceData } from './../../providers/xrm/service-data.provider';
import { CacheService } from './../cache.service';
import { ModalsService } from './../modals.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';

@Injectable({
    providedIn: 'root',
})

export class ServicesService {
    private apiPaths: any = {
        categories: 'client/categories',
        services: 'client/services',
    };
    private xrmConnection: SDKServicesDataService;
    private SDKClientsService: SDKClientService;

    public lastSelectedServiceId = null; // using when change language to reFetch initChoice fot this serviceId

    private servicesWithoutInitChoice = [];
    private sessionType: any = null;

    /**
     * Imports
     * @param {LoaderService} _loaderService
     */
    constructor(
        private _loaderService: LoaderService,
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _orderData: OrderData,
        private _modalsService: ModalsService,
        private _mainDataProvider: MainDataProvider,
        private _errorReportingService: ErrorReportingService,
        private _serviceDataProvider: ServiceData,
        private _cacheService: CacheService,
        private _errorHandlingService: ErrorHandlingService,
        private _localizationProvider: LocalizationProvider,
    ) {
        this.xrmConnection = this._XRMApiCommunicatorService.getServicesDataService();

        this.SDKClientsService = this._XRMApiCommunicatorService.getClientService();

        const errorHandlerDepMethods: Array<DependencyMethod> = [
            {
                name: 'fetchServicesInitChoices',
                call: this.fetchServicesInitChoices.bind(this),
            },
            {
                name: 'getChoicesByPositions',
                call: this.getChoicesByPositions.bind(this),
            },
        ];

        // Provide methods needed for ErrorHandlingService in order to let it take the proper action
        this._errorHandlingService.setDepMethods(errorHandlerDepMethods);
    }

    /**
     * Performs request for categories
     * @param useCache
     * @param callback
     */
    private fetchCategories(useCache?: boolean, callback?: Function): Promise<any> {
        // const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg20'));
        const queryParams: SDKQueryParams = {
                expand: [
                    'all',
                ],
                'paging[limit]': '9999',
            };

        return new Promise((resolve: Function, reject: Function) => {
            this.xrmConnection.getCategories(queryParams)
                .then(
                    (response: any) => { // Success
                        if (!response.error) {
                            // Set response in cache if cache is turned on
                            if (useCache) {
                                this._cacheService.setCache('Categories', response.data, 1, this.apiPaths.categories);
                            }

                            // Set categories in provider
                            this._serviceDataProvider.setCategories(response.data);

                            // Extract services and set them in provider and cache (if it's turned on)
                            this.extractServices(response.data, useCache);

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            // this._loaderService.hideLoader(loader);

                            resolve(response); // !!! issue with wrong response debug search for: #e277c5 !!!
                        } else {
                            // Open modal
                            this._modalsService.open('defaultError');

                            // Send report
                            if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                                const errorModel: any = {
                                    reason: 'Unexpected Validation Error on fetching categories',
                                    errorResponse: response,
                                },
                                    reportSubject: string = errorModel.reason;

                                this._errorReportingService.report(
                                    {
                                        emailErrors: [errorModel],
                                    },
                                    false,
                                    reportSubject,
                                );
                            }

                            // this._loaderService.hideLoader(loader);

                            reject();
                        }
                    },
                    (response: any) => { // Error
                        this._errorHandlingService.validateResponse(response);

                        if (typeof callback === 'function') {
                            callback(response);
                        }

                        // this._loaderService.hideLoader(loader);

                        reject();
                    },
                ).catch((error) => {
                    // Open modal
                    this._modalsService.open('defaultError');

                    if (typeof callback === 'function') {
                        callback(error);
                    }

                    // this._loaderService.hideLoader(loader);

                    reject();
                });
        });
    }

    /**
     * Catch all services
     * is hard core the paging limit to the 9999
     */
    public fetchServices(useCache?: boolean): Promise<any> {
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg21')),
            queryParams: SDKQueryParams = { expand: ['all.all.all'], 'paging[limit]': '9999' };

        return new Promise((resolve, reject) => {
            this.xrmConnection.getServices(queryParams)
                .then(
                    (response: any) => {
                        this._loaderService.hideLoader(loader);

                        if (!response.error) {
                            this._serviceDataProvider.setServices(response.data);

                            // Set response in cache if cache is turned on
                            if (useCache) {
                                this._cacheService.setCache('Services', response.data, 1, this.apiPaths.services);
                            }

                            resolve(response.data);
                        } else {
                            // Open modal
                            this._modalsService.open('defaultError');

                            // Send report
                            if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                                const errorModel: any = {
                                    reason: 'Unexpected Validation Error on fetching services',
                                    errorResponse: response,
                                },
                                    reportSubject: string = errorModel.reason;

                                this._errorReportingService.report(
                                    {
                                        emailErrors: [errorModel],
                                    },
                                    false,
                                    reportSubject,
                                );
                            }

                            reject();
                        }
                    },
                    (response: any) => {
                        this._errorHandlingService.validateResponse(response);

                        this._loaderService.hideLoader(loader);

                        reject();
                    },
                ).catch((error) => {
                    // Open modal
                    this._modalsService.open('defaultError');

                    this._loaderService.hideLoader(loader);

                    reject();
                });
        });
    }

    public fetchService(
        serviceId?: string | number,
        reqConfig?: RequestConfig,
    ): Promise<ServerResponse<Service>> {

        if (serviceId) {
            this.lastSelectedServiceId = serviceId;
        }

        return new Promise((res, rej) => {
            const requestConfig = {
                queryParams: {
                    expand: ['all.all.all.all'],
                    filter: {
                        choices: {
                            positions: [ChoicesPositions.Init],
                        },
                    },
                },
                validate: true,
            };

            Object.assign(requestConfig, reqConfig);

            const loader = this._loaderService.showLoader();

            // Use last service id when change language to refetch init choice
            const requestId = serviceId ? serviceId : this.lastSelectedServiceId;

            const subscription = from(this.xrmConnection.getService(requestId, requestConfig.queryParams)).pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            ).subscribe(
                (serverResponse: ServerResponse<Service>) => {
                    if (requestConfig.validate) {
                        serverResponse.validationCallback = this._errorHandlingService.validateResponse(serverResponse);
                    }

                    if (serverResponse && !serverResponse.error) {

                        this._serviceDataProvider.setLastServiceInitData(serverResponse.data);
                        res(serverResponse);

                    } else {
                        this._modalsService.open('defaultError');

                        const errorModel: any = {
                            reason: 'Unexpected Validation Error on fetching service init choices',
                            errorResponse: serverResponse,
                        },
                            reportSubject: string = errorModel.reason;

                        this._errorReportingService.report(
                            {
                                emailErrors: [errorModel],
                            },
                            false,
                            reportSubject,
                        );

                        rej(serverResponse);
                    }
                    subscription.unsubscribe();
                },
                (error: ServerResponse<Service>) => {
                    if (requestConfig.validate) {
                        error.validationCallback = this._errorHandlingService.validateResponse(error);
                    }
                    rej(error);
                    subscription.unsubscribe();
                },
            );

        });
    }

    /**
     * Fetch init choice items for the specific service
     * @param serviceId
     */
    public fetchServicesInitChoices(serviceId, hideLoaderOnSuccess: boolean, passCache?: boolean, callback?: Function): Promise<any> {
        const loader = this._loaderService.showLoader(),
            queryParams: SDKQueryParams = {
                expand: ['choices.all'],
                filter: {
                    choices: {
                        positions: [ChoicesPositions.Init],
                    },
                },
            };

        return new Promise((resolve: Function, reject: Function) => {
            this.xrmConnection.getService(serviceId, queryParams)
                .then(
                    (response: any) => { // Success

                        if (!response.error) {
                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            if (hideLoaderOnSuccess) {
                                this._loaderService.hideLoader(loader);
                            }

                            this._serviceDataProvider.setLastServiceInitData(response.data);

                            resolve(response.data);
                        } else {
                            // Open modal
                            this._modalsService.open('defaultError');

                            // Send report
                            if (!this._mainDataProvider || this._mainDataProvider.obfEnv.env === 'development' || this._mainDataProvider.obfEnv.env === 'stage' || this._mainDataProvider.obfEnv.env === 'production') {
                                const errorModel: any = {
                                    reason: 'Unexpected Validation Error on fetching service init choices',
                                    errorResponse: response,
                                },
                                    reportSubject: string = errorModel.reason;

                                this._errorReportingService.report(
                                    {
                                        emailErrors: [errorModel],
                                    },
                                    false,
                                    reportSubject,
                                );
                            }

                            reject();
                        }
                    },
                    (response: any) => { // Error
                        this._errorHandlingService.validateResponse(response);

                        if (typeof callback === 'function') {
                            callback(response);
                        }

                        if (hideLoaderOnSuccess) {
                            this._loaderService.hideLoader(loader);
                        }

                        reject();
                    },
                ).catch((error) => {
                    // Open modal
                    this._modalsService.open('defaultError');

                    if (typeof callback === 'function') {
                        callback(error);
                    }

                    if (hideLoaderOnSuccess) {
                        this._loaderService.hideLoader(loader);
                    }

                    reject();
                });
        });

        // const subscription = from(this.xrmConnection.getService(serviceId, queryParams, passCache))
        //     .pipe(
        //         finalize(() => {
        //             if (hideLoaderOnSuccess) {
        //                 this._loaderService.hideLoader(loader);
        //             }
        //         })
        //     )
        //     .subscribe(
        //         (response: any) => {
        //             if (!response.error) {
        //                 this.serviceInitChoices.next((this.cachedResponseInitChoiceItems = response.data));

        //                 if (typeof callback === 'function') {
        //                     callback(response);
        //                 }
        //             } else {
        //                 // Open modal
        //                 this._modalsService.open('defaultError');
        //             }
        //             subscription.unsubscribe();

        //         },
        //         response => {
        //             // Open modal
        //             this._modalsService.open('defaultError');

        //             console.error('Request fail: fetchServicesInitChoices()', response);
        //             if (typeof callback === 'function') {
        //                 callback(response);
        //             }
        //             subscription.unsubscribe();

        //         }
        //     );
    }

    /**
     * Get last service init data
     */
    public getLastServiceInitData(): any {
        return this._serviceDataProvider.getLastServiceInitData();
    }

    // Get init choice value from last selected service
    public getPostcodeFromInitData() {
        const selectedServiceData = this.getLastServiceInitData();
        const choices = selectedServiceData?.choices?.length ? selectedServiceData?.choices : null;

        if (!choices) return null;

        const initChoice = choices.find((choice) => {
            if (choice.hasOwnProperty('positions') && choice.positions) {
                return choice.positions?.indexOf(ChoicesPositions.Init) > -1;
            }
        });

        return initChoice &&
            initChoice.choice_items &&
            initChoice.choice_items.length
            ? initChoice.choice_items[0].value.postcode
            : null;
        
    }

    /**
     * Extract services from category request
     */
    private extractServices(categories: Array<Category>, useCache: boolean) {

        const extractedServices = [];

        if (categories && categories.length) {
            for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
                const category: Category = categories[categoryIndex];

                if (category.services && category.services.length) {
                    for (let serviceIndex = 0; serviceIndex < category.services.length; serviceIndex++) {
                        const service: Service = category.services[serviceIndex];

                        // some services are added to more than one category
                        const serviceExist = extractedServices.find((el) => el.id === service.id);

                        if (!serviceExist) {
                            extractedServices.push(service);
                        }
                    }
                }
            }
        }

        // Set response in cache if cache is turned on
        if (useCache) {
            this._cacheService.setCache('Services', extractedServices, 1, this.apiPaths.services);
        }

        this._serviceDataProvider.setServices(extractedServices);
    }

    /**
     * Get categories by 3 level logic
     *  - from provider
     *  - from cache
     *  - by request
     * @param useCache decide wether not to use cashe or not
     */
    public getCategories(useCache?: boolean): Promise<Array<Category>> {
        const categories: Array<Category> = this._serviceDataProvider.getCategories();

        return new Promise((resolve: Function, reject: Function) => {
            if (!categories || !categories.length) {
                if (useCache && this._cacheService.checkCache('Categories', this.apiPaths.categories)) {
                    const categories: Array<Category> = this._cacheService.getCache('Categories').data;

                    this._serviceDataProvider.setCategories(categories);

                    this.extractServices(categories, useCache);

                    // resolve with cached services
                    resolve(categories);
                } else {
                    this.fetchCategories(useCache)
                        .then(
                            (categories: any) => { // Success
                                resolve(categories.data);
                            },
                            () => { // Error
                                reject();
                            },
                        );
                }
            } else {
                // Return categories from provider's instance
                resolve(categories);
            }

        });
    }

    /**
     * Get services by 3 level logic
     *  - from provider
     *  - from cache
     *  - by request
     * @param useCache decide wether not to use cashe or not
     */
    public getServices(useCache?: boolean): Promise<Array<Service>> {
        const services: Array<Service> = this._serviceDataProvider.getServices();

        return new Promise((resolve: Function, reject: Function) => {
            if (!services || !services.length) {
                if (useCache && this._cacheService.checkCache('Services', this.apiPaths.services)) {
                    const services: Array<Service> = this._cacheService.getCache('Services').data;

                    this._serviceDataProvider.setServices(services);

                    // resolve with cached services
                    resolve(services);
                } else {
                    this.fetchServices(useCache)
                        .then(
                            (services: Array<Service>) => { // Success
                                resolve(services);
                            },
                            () => { // Error
                                reject();
                            },
                        );
                }
            } else {
                // Return categories from provider's instance
                resolve(services);
            }

        });
    }

    /**
     * Extract service by id
     * @param serviceId
     */
    public async getServiceById(serviceId: string | number) {
        const services: Array<Service> = await this.getServices();

        // eslint-disable-next-line radix
        serviceId = typeof serviceId === 'string' ? parseInt(serviceId) : serviceId;

        return services.find((service) => service.id === serviceId);
    }

    /**
     * Gets category by id
     * @param serviceId
     * @returns Category Object
     */
    public getCategoryByServiceId(serviceId): Promise<Category | null> {
        return new Promise<Category | null>((resolve: Function, reject: Function) => {
            this.getCategories(true)
                .then(
                    (categories: Array<Category>) => { // Success
                        if (categories && categories.length !== 0) {
                            const matchedCategories: Array<Category> = categories.filter((category) => {
                                if (category.services) {
                                    return category.services && category.services.find((service) => service.id === serviceId);
                                }
                            });

                            if (matchedCategories && matchedCategories.length) {
                                resolve(matchedCategories[0]);
                            } else {
                                resolve(null);
                            }
                        } else {
                            resolve(null);
                        }
                    },
                    () => { // Error
                        resolve(null);
                    },
                ).catch((error) => {
                    resolve(null);
                });
        });
    }

    /**
     * Find first choice by position
     * @param choices
     * @param choiceItemType
     * @param choicePosition
     */
    public findFirstChoiceItemByTypeAndPosition(choices, choiceItemType, choicePosition) {
        let choice,
            lastPassedChoice,
            success = false;

        if (!choices) {
            return;
        }

        choices.filter((el) => {
            if (el.hasOwnProperty('positions') && el.positions) {
                return el.positions.indexOf(choicePosition) !== -1;
            }
        }).recursiveMap((el) => {

            if (!el.hasOwnProperty('parent_id')) {
                lastPassedChoice = el;
            }

            if (el.type === choiceItemType && !success) {
                success = true;
                choice = lastPassedChoice;
            }

            return el.choice_items;
        });

        return choice;
    }

    public checkChoiceItemTypeByParentId(choices, parentId, choiceItemType) {
        let result = false;

        choices.forEach(
            (choice) => {
                if (choice.id === parentId && choice.choice_items?.length) {
                    choice.choice_items.forEach(
                        (item) => {
                            result = item.type === choiceItemType;
                            return;
                        }
                    )
                }
            }
        )
        return result;
    }

    public findAllChoicesByChoiceItemType(choices, choiceItemType, choicePosition = null) {
        let findChoices = [],
            lastPassedChoice;

        if (!choices) {
            return;
        }

        choices.filter((el) => {
            if (choicePosition && el.hasOwnProperty('positions') && el.positions) {
                return el.positions.indexOf(choicePosition) !== -1;
            } else {
                return true;
            }
        }).recursiveMap((el) => {

            if (!el.hasOwnProperty('parent_id')) {
                lastPassedChoice = el;
            }

            if (el.type === choiceItemType) {
                findChoices.push(lastPassedChoice);
            }

            return el.choice_items;
        });

        return findChoices;
    }

    /**
     * Extract choice by passed choice_item id
     * @param choices
     * @param choiceItemId
     */
    public findChoiceByChoiceItemId(choices: any[], choiceItemId) {
        let choice,
            lastPassedChoice,
            index = -1,
            success = false;
        choices.recursiveMap((el) => {

            if (!el.hasOwnProperty('parent_id')) {
                lastPassedChoice = el;
            }

            if (el.id === choiceItemId) {
                success = true;
                choice = lastPassedChoice;
            }

            return el.choice_items;
        });

        if (success) {
            index = choices.indexOf(choice);
        }

        return { choice, choiceIndex: index };
    }

    public setServicesWithoutInitChoice(serviceId) {
        this.servicesWithoutInitChoice.push(serviceId);
    }

    public isServiceMissingInitChoice(serviceId) {
        return this.servicesWithoutInitChoice.indexOf(serviceId) > -1;
    }

    public setSessionType(type) {
        this.sessionType = type;
    }

    public getSessionType() {
        return this.sessionType;
    }

    /**
     * Extract choices by positions
     * @param choices
     * @param position
     */
    public getChoicesByPositions(choices: any[], position: string) {
        if (!choices) {
            return [];
        }

        return choices.slice().filter((el) => {
            if (el.hasOwnProperty('positions') && el.positions) {
                return el.positions.indexOf(position) !== -1;
            }
        });
    }

    /**
     * Search for service with phrase
     * @param queryParams
     */
    public getServiceSearchResults(queryParams?: SDKQueryParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.SDKClientsService.getServiceSearchResults(queryParams)
                .then(
                    (response: any) => {
                        if (!response.error) {
                            resolve(response.data);
                        } else {
                            reject();
                        }
                    },
                    (response: any) => {
                        this._errorHandlingService.validateResponse(response);

                        reject();
                    },
                ).catch((error) => {
                    // Open modal
                    this._modalsService.open('defaultError');

                    reject();
                });
        });
    }
}
