import { ChoiceItemsTypes } from '@constants/choice-items';
import { Choice } from '@models/choice';
import { ChoiceItem } from '@models/choice-item';
import moment from 'moment-mini';
import { getCountryISO2 } from '../helpers/countries-iso2';
import { getCountryISO3 } from '../helpers/countries-iso3';

export function dateFormat(language: string) {
    return {
        convertTo(pattern, date) {
            let resultDate;
            const dateTime = new Date(date).getTime();
            const time = moment.utc(dateTime);

            if (language !== 'en') {
                require(`./moment-locales/${language}`);
            }

            moment.locale(language);

            switch (pattern) {
                // example: Sat
                case 'dayOnly':
                    resultDate = time.format('ddd');
                    break;

                // example: Saturday
                case 'fullDay':
                    resultDate = time.format('dddd');
                    break;

                // example: December
                case 'fullMonth':
                    resultDate = time.format('MMMM');
                    break;

                // example: 17
                case 'dateOnly':
                    resultDate = time.format('DD');
                    break;

                // example: December 2019
                case 'fullMonthAndYear':
                    resultDate = time.format('MMMM YYYY');
                    break;

                // example: Nov 17
                case 'shortDate':
                    resultDate = time.format('MMM DD');
                    break;

                // example: 2017-11-17
                case 'date':
                    resultDate = time.format('YYYY-MM-DD');
                    break;

                // example: Fri, Nov 17,
                case 'beautyDay':
                    resultDate = time.format('ddd, MMM DD');
                    break;

                // example: 17 Nov 2017, Friday
                case 'beautyDate':
                    resultDate = time.format('DD MMM YYYY, dddd');
                    break;

                // example: 17 Nov 2017, Friday
                case 'revertBeautyDate':
                    resultDate = time.format('dddd, DD MMM YYYY');
                    break;
            }

            return resultDate;
        },
    };
}

export function getDomainName(hostName: string, level?: number) {
    let domain = '';

    if (hostName != null && hostName.length !== 0) {
        // find & remove protocol (http, ftp, etc.) and get domain
        if (hostName.indexOf('://') > -1) {
            domain = hostName.split('/')[2];
        } else {
            domain = hostName.split('/')[0];
        }

        // find & remove port number
        domain = domain.split(':')[0];
        // domain = domain.replace('www.', '');
        // domain = domain.replace('obf.', '');
        // domain = domain.replace('accounts.', '');
        // domain = domain.replace('blog.', '');
        // domain = domain.replace('business.', '');
        // domain = domain.replace('accounts-dev.', '');
        // domain = domain.replace('accounts-stg.', '');

        if (level) {
            domain = domain.split('.').slice(-level).join('.');
        }
    }

    return domain;
}

export function getUrlVars(hackTheSearchQ?): { [key: string]: any } {
    let vars = {},
        hash;
    const hashes = (hackTheSearchQ ? hackTheSearchQ : window.location.search)
        .substr(1)
        .split('&');
    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars[decodeURI(hash[0])] = decodeURI(hash[1]);
    }

    return vars;
}

/**
 * Extract query parameters from deep link
 * @param deepLink
 */
export function extractParamsFromDeepLink(deepLink: string): {
    [key: string]: any;
} {
    const parsedDeepLink: string =
            deepLink.indexOf('?') !== -1
                ? deepLink.substring(deepLink.indexOf('?'))
                : deepLink,
        urlVars: any = getUrlVars(parsedDeepLink);

    const parsedParams: any = {};

    // Check for payload properties
    Object.keys(urlVars).map((queryParamsKey) => {
        if (
            queryParamsKey.indexOf('fs_payload') !== -1 &&
            queryParamsKey.indexOf(']') !== -1 &&
            queryParamsKey.indexOf('[') !== -1
        ) {
            const splittedParam = queryParamsKey.split('[');
            const payloadProperty = splittedParam[1].split(']')[0];

            if (payloadProperty) {
                parsedParams[payloadProperty] = urlVars[queryParamsKey];
            }
        }
    });

    return parsedParams;
}

/**
 * filter choices from not use data.
 * @param choices
 */
export function simplifyChoices(choices) {
    function filterData(choiceItems) {
        if (!choiceItems) {
            return null;
        }

        return choiceItems
            .map((el) => {
                return {
                    id: el.id,
                    value:
                        el.type !== ChoiceItemsTypes.Attachment
                            ? el.value
                            : // eslint-disable-next-line @typescript-eslint/no-shadow
                              el.value.map((el: any) => {
                                  return { token: el.token };
                              }),
                    choice_items: filterData(el.choice_items),
                };
            })
            .filter((el) => el != null);
    }

    if (!choices) {
        return [];
    }

    return choices.map((el) => {
        return {
            id: el.id,
            choice_items: filterData(el.choice_items),
        };
    });
}

export function scrollToTop(selector?) {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
        window.navigator.userAgent
    );

    // IE doesn't support scrollTo
    if (isIEOrEdge) {
        return;
    }

    let element = document.querySelector('.obf-header-section');

    if (selector) {
        element = document.querySelector(selector);
        if (element) {
            if (typeof element.scrollTo === 'function') {
                element.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }

            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });

            return;
        }
    }

    if (element) {
        element.scrollIntoView();
    }
}

declare global {
    interface Window {
        mozRequestAnimationFrame: any;
        msRequestAnimationFrame: any;
    }
}

export function scrollElementInCenter(
    elementSelector,
    scrolledElementSelector
) {
    setTimeout(() => {
        const element = document.querySelector(elementSelector);
        if (element) {
            const elementRect = element.getBoundingClientRect();

            const elementCenter = elementRect.top - elementRect.height / 2;

            if (elementCenter !== document.documentElement.clientHeight / 2) {
                const scrollElement = document.querySelector(
                    scrolledElementSelector
                );
                if (scrollElement) {
                    scrollToPixel(
                        scrollElement.scrollTop +
                            elementCenter -
                            document.documentElement.clientHeight / 2,
                        300,
                        scrollElement
                    );
                }
            }
        }
    }, 1);
}

export function scrollToPixel(toPixel, duration?, scrollElement?, callback?) {
    animatedScrollTo(toPixel);

    function animatedScrollTo(to) {
        function move(amount) {
            if (scrollElement) {
                scrollElement.scrollTop = amount;
                return;
            }
            const bodyParent: any = document.body.parentNode;
            document.documentElement.scrollTop = amount;
            bodyParent.scrollTop = amount;
            document.body.scrollTop = amount;
        }

        let start = scrollElement ? scrollElement.scrollTop : scrollHeight(),
            change = to - start,
            currentTime = 0,
            increment = 20;
        duration = typeof duration === 'undefined' ? 500 : duration;

        const requestAnimFrameFormPlugin =
            window.requestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.msRequestAnimationFrame;

        const animateScroll = function () {
            currentTime += increment;
            const val = easeInOutQuad(currentTime, start, change, duration);
            move(val);
            if (currentTime < duration) {
                requestAnimFrameFormPlugin(animateScroll);
            } else {
                if (callback) {
                    callback();
                }
            }
        };

        const easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return (c / 2) * t * t + b;
            }
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        animateScroll();
    }
}

export function scrollHeight() {
    if (window === parent) {
        return (
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop
        );
    } else {
        return (
            window.pageYOffset ||
            window.document.documentElement.scrollTop ||
            window.document.body.scrollTop ||
            window.outerHeight
        );
    }
}

/**
 * Get Hotjar user id
 */
export function getHotjarId() {
    let hotJarSessionId;
    try {
        hotJarSessionId = window.hj.pageVisit.property
            .get('userId')
            .split('-')
            .shift();
    } catch (e) {
        try {
            hotJarSessionId = window.hj.globals
                .get('userId')
                .split('-')
                .shift();
        } catch (e) {
            hotJarSessionId = 'N/A';
        }
    }

    return hotJarSessionId;
}

/**
 * Find address choice item by passed service choice
 * @param {object} choice service choice
 */
export function findChoiceItemByType(
    choice: Choice | ChoiceItem,
    choiceItemType: string
): ChoiceItem {
    const choiceItems: Array<ChoiceItem> = choice.choice_items;

    for (let index = 0; index < choiceItems.length; index++) {
        const choiceItem = choiceItems[index];
        if (choiceItem.type === choiceItemType) {
            return choiceItem;
        }

        if (
            choiceItem.hasOwnProperty('choice_items') &&
            choiceItem.choice_items
        ) {
            return findChoiceItemByType(choiceItem, choiceItemType);
        }
    }
}

/**
 * Find address choice item by passed service choice
 * @param {object} choice service choice
 */
export function findChoiceByChoiceItemType(
    choices: Choice[],
    searchChoiceItemType: ChoiceItemsTypes
) {
    let choice = null;
    let choiceItem = null;
    choices.recursiveMap((el) => {
        if (choice && choiceItem) {
            return el.choice_items;
        }

        if (!choiceItem && el.positions) {
            choice = el;
        }

        if (el.type === searchChoiceItemType) {
            choiceItem = el;
        }

        return el.choice_items;
    });

    return choice;
}

/**
 * Map language keywords - example values: en_GB, EN, en
 */
export function transformLanguageKey(key: string): string {
    return key.split(/[\s_]/)[0].toLocaleLowerCase();
}

export function getPreferredCountriesKeys(countries: string[]): string[] {
    const countriesKeys: string[] = [];

    if (countries && countries.length) {
        countries.map((country) => {
            let countryKey: string = '';
            let countryUpperKeys = country.toUpperCase();
            let isISO3 = countryUpperKeys.length === 3 ? true : false;
            let regionNames;

            countryKey = isISO3 ? getCountryISO2(countryUpperKeys) : countryUpperKeys;
            
            // countriesKeys remains empty array for old browsers which do not support new Intl['DisplayNames']
            try {
                regionNames = new Intl['DisplayNames'](['en'], {type: 'region'});
                countriesKeys.push(regionNames.of(countryKey).replace(/ /g,''));
            } catch(e) {
                
            }
        });
    }

    return countriesKeys;
}

export function getGoogleCookieId(gclValue) {
    // Split the string by dot
    var parts = gclValue.split('.');

    // Check if there are at least three parts
    if (parts.length < 3) {
        return null; // or handle error as needed
    }

    // Rejoin the parts after the second dot
    return parts.slice(2).join('.');
}

export function getMetaCookieId(value) {
    // Split the string by dot
    var parts = value.split('.');

    // Check if there are at least three parts
    if (parts.length < 4) {
        return null; // or handle error as needed
    }

    // Rejoin the parts after the second dot
    return parts.slice(3).join('.');
}

export function getBingCookieId(value) {
    // Split the string by dot
    var result = '';

    if (value?.indexOf('_uet') > -1) {
        result = value.replace('_uet', '');
    }

    return result;
}

/**
 * 
 * @param cookieName 
 * @param value 
 * @param expire in months 
 */
  export function  setCookie(cookieName: string, value: string, expire?: number) {
        const domainName = getDomainName(window.location.hostname);
        const date: any = new Date(); 

        date.setMonth(date.getMonth() + expire);

        document.cookie = `${cookieName}=${value};expires=${date.ра()};path=/; domain=.${domainName};`;
        
    }
