import { ApiResponseStatus } from './../../Api/Interfaces/ApiResponseStatus';
import { SDKQueryParams } from '../../Api/Interfaces/SDKQueryParams';
import { BackEndAccountsService } from '../backendservices/BackEndAccountsService';
import { BackEndXrmService } from '../backendservices/BackEndXrmService';
import { ParamsOptionsBuilder } from '../../Helpers/ParamsOptionsBuilder';
import { tap, map } from 'rxjs/operators';
import ObfInjector from '../../Helpers/Injector';
import { Authorize } from '../../Api/ApiAuthorize';
import { SDKValidationResponseService } from '../SDKValidationResponseService';

export class SDKAuthenticationService {
    private _backEndXrmService: BackEndXrmService;
    private _backEndAccountsService: BackEndAccountsService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;
    private _validatorResponse: SDKValidationResponseService;
    private apiPoints = {
        loginXRM: 'client/login',
        login: 'ext/login',
        logoutXRM: 'client/logout',
        logout: 'ext/logout',
        register: 'ext/register',
        registerXRM: 'client/register',
        anonymousRegister: 'client/register',
        socialProviders: 'client/social_providers',
        accountsMenu: 'ext/get-auth'
    };

    constructor() {
        this._validatorResponse =
            ObfInjector.getRegistered('SDKValidationResponseService') as SDKValidationResponseService;
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
        this._backEndAccountsService = ObfInjector.getRegistered('BackEndAccountsService') as BackEndAccountsService;
        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
    }

    /**
     * Log user
     * @param {Object} data login data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public loginXRM(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.loginXRM + '/' + params, data)
                .pipe(
                    tap(response => {
                        if (response.data && response.data.session) {
                            // Set session id in obf session
                            const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                            auth.authorization = response.data.session.sid;
                        }
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Log user
     * @param {Object} data login data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public login(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndAccountsService
                .post(this.apiPoints.login + params, data, { withCredentials: true })
                .pipe(
                    tap(response => {
                        if (response.data && response.data.session) {
                            // Set session id in obf session
                            const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                            auth.authorization = response.data.session.sid;
                        }
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Logout user
     * @returns {Promise}
     */
    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndAccountsService
                .get(this.apiPoints.logout, { withCredentials: true })
                .subscribe(
                    res => { // Success
                        // Remove session id in obf session
                        const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                        auth.authorization = null;

                        resolve(res);
                    },
                    err => { // Error
                        const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                        auth.authorization = null;

                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Logout user
     * @returns {Promise}
     */
    public logoutXRM(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.logoutXRM, null)
                .subscribe(
                    res => { // Success
                        // Remove session id in obf session
                        const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                        auth.authorization = null;

                        resolve(res);
                    },
                    err => { // Error
                        const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                        auth.authorization = null;

                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Normal registration
     * @param {Object} data register data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public register(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndAccountsService
                .post(this.apiPoints.register + params, data, { withCredentials: true })
                .pipe(
                    tap(response => {
                        if (response.data && response.data.session) {
                            // Set session id in obf session
                            const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                            auth.authorization = response.data.session.sid;
                        }
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Normal registration
     * @param {Object} data register data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public registerXRM(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.registerXRM + params, data)
                .pipe(
                    tap(response => {
                        if (response.data && response.data.session) {
                            // Set session id in obf session
                            const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                            auth.authorization = response.data.session.sid;
                        }
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Anonymous registration
     * @param {Object} data register data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public anonymousRegister(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.anonymousRegister + params, data)
                .pipe(
                    tap((response: any) => {
                        if (response.data && response.data.session) {
                            // Set session id in obf session
                            const auth = ObfInjector.getRegistered('Authorize') as Authorize;

                            auth.authorization = response.data.session.sid;
                        }
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Get user menu from accounts
     * @returns {Promise}
     */
    public getAccountsMenu(csid?: any, currentLanguage?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let params = csid ? '?csid=' + csid : '';
            if (currentLanguage && csid) {
                params += '&lang=' + currentLanguage;
            } else if (currentLanguage) {
                params = '?lang=' + currentLanguage
            }

            const subscriber = this._backEndAccountsService
                .get(this.apiPoints.accountsMenu + params, { withCredentials: true })
                .pipe(
                    tap(resData => {
                        // console.log(resData);
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Get social providers
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getSocialProviders(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.socialProviders + params, { validateResponseType: 'socialProvidersResponse' })
                .pipe(
                    tap((resData: ApiResponseStatus) => {
                        // console.log(resData);
                    })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
