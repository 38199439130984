import { Position } from '@models/position';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChoicesPositions } from 'src/app/constants/choice';

import { Injectable } from '@angular/core';
import { Address } from '@models/address';
import { DependencyMethod } from '@models/dependency-method';
import { Phone } from '@models/phone';
import { User } from '@models/user';
import { UserApplications } from '@models/user-applications';

import { ObfOptions } from '../../../../lib/obf-client/src/interfaces/obf-options.interface';
import {
    BatchRequestStructure,
} from '../../../../lib/xrm-sdk/src/lib/Api/Interfaces/BatchRequestStructure';
import { SDKQueryParams } from '../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import {
    SDKAuthenticationService,
} from '../../../../lib/xrm-sdk/src/lib/Services/authentication/SDKAuthenticationService';
import { SDKAccountService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKAccountService';
import {
    SDKBatchRequestService,
} from '../../../../lib/xrm-sdk/src/lib/Services/SDKBatchRequestService';
import { SDKClientService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKClientService';
import { SDKSharedService } from '../../../../lib/xrm-sdk/src/lib/Services/SDKSharedService';
import { environment } from '../../../environments/environment';
import { genLongGUID } from '../../helpers/helper-functions';
import { MainDataProvider } from '../../providers/xrm/main-data.provider';
import { OrderData } from '../../providers/xrm/order-data.provider';
import { UserData } from '../../providers/xrm/user-data.provider';
import { ErrorHandlingService } from '../errors/error-handling.service';
import { ErrorReportingService } from '../errors/error-reporting.service';
import { LoaderService } from '../loader.service';
import { NavigationService } from '../navigation.service';
import { PopupModalsService } from '../popup-modals.service';
import { PostMessageService } from '../post-message.service';
import { ConfigurationService } from './configuration.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { CryptoService } from '@services/crypto.service';
import { LocalizationProvider } from '@providers/localization.provider';

declare global {
    interface Window {
        _FSAccountMenu: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    private sdkClientService: SDKClientService;
    private sdkSharedService: SDKSharedService;
    private sdkAuthService: SDKAuthenticationService;
    private sdkAccountService: SDKAccountService;
    private sdkBatchRequestService: SDKBatchRequestService;

    /**
     * Imports
     */
    constructor(
        private loaderService: LoaderService,
        private userDataProvider: UserData,
        private XRMApiCommunicatorService: XRMApiCommunicatorService,
        private errorHandlingService: ErrorHandlingService,
        private deviceService: DeviceDetectorService,
        private mainDataProvider: MainDataProvider,
        private errorReportingService: ErrorReportingService,
        private postMessageService: PostMessageService,
        private orderData: OrderData,
        private configurationService: ConfigurationService,
        private popupModalsService: PopupModalsService,
        private navigationService: NavigationService,
        private _eventTrackingService: EventTrackingService,
        private cryptoService: CryptoService,
        private _localizationProvider: LocalizationProvider
    ) {
        // console.error('Init userDataService');
        this.sdkClientService = this.XRMApiCommunicatorService.getClientService();
        this.sdkSharedService = this.XRMApiCommunicatorService.getSharedService();
        this.sdkAuthService = this.XRMApiCommunicatorService.getAuthenticationService();
        this.sdkAccountService = this.XRMApiCommunicatorService.getAccountService();
        this.sdkBatchRequestService = this.XRMApiCommunicatorService.getBatchRequestService();

        const errorHandlerDepMethods: Array<DependencyMethod> = [
            {
                name: 'fetchUserData',
                call: this.fetchUserData.bind(this),
            },
        ];

        // Provide methods needed for ErrorHandlingService in order to let it take the proper action
        this.errorHandlingService.setDepMethods(errorHandlerDepMethods);
    }

    public getUserData(): User {
        return this.userDataProvider.getUserData();
    }

    /**
     * Fetch the user data from middle point
     * @param callback
     */
    public fetchUserData(
        queryParams: SDKQueryParams,
        callback?: Function,
        skipInterceptor = false,
    ) {

        const bookConfiguration = this.configurationService.getConfigurationSettings()?.book;
        let isAccountMenuHidden = false;

        if (bookConfiguration?.settings?.hide_accounts_menu) {
            isAccountMenuHidden = true;
        }
        const loader = this.loaderService.showLoader();
        const subscription = from(
            this.sdkClientService.fetchUserData(queryParams, skipInterceptor),
        )
            .pipe(finalize(() => { }))
            .subscribe(
                async (response: any) => {
                    this.errorHandlingService.validateResponse(response);

                    const authRestrictions = await this.authRestrictions();

                    if (!isAccountMenuHidden && authRestrictions !== "anonym_auth" && response.data.type_id !== 1) {

                        // Set user data
                        this.userDataProvider.setUserData(response.data);

                        //NEW ACC MENU
                        this.buildAccountsMenu();

                        // Set user applications
                        this.setUserApplications();

                        this.loaderService.hideLoader(
                            loader,
                        );

                        callback(response);

                        // Get accounts menu config
                        this.fetchAccountsMenuConfig()
                            .then(
                                (accountsMenuConfig: any) => {

                                    // // Success
                                    // // Set accounts menu config
                                    // this.setAccountsMenuConfig(
                                    //     accountsMenuConfig,
                                    // );

                                    // this.userDataProvider.setUserData(response.data);

                                    // // Set user applications
                                    // this.setUserApplications();

                                    // this.loaderService.hideLoader(
                                    //     loader,
                                    // );

                                    // callback(response);
                                },
                                () => {
                                    // // Error
                                    // // Set user data
                                    // this.userDataProvider.setUserData(response.data);

                                    // // Set user applications
                                    // this.setUserApplications();

                                    // this.loaderService.hideLoader(
                                    //     loader,
                                    // );

                                    // callback(response);
                                },
                            )
                            .catch((error) => {
                                // Set user data
                                // this.userDataProvider.setUserData(response.data);

                                // Set user applications
                                // this.setUserApplications();

                                // this.loaderService.hideLoader(loader);

                                // callback(response);
                            });
                    } else {
                        // Accounts are disabled
                        // Set user data
                        this.userDataProvider.setUserData(response.data);

                        // Set user applications
                        this.setUserApplications();

                        this.loaderService.hideLoader(loader);

                        callback(response);
                    }
                    
                    // We need user data to feed the DataLayer
                    const dataLayerObject: any = {};
                    const userData = response.data;

                    const userId: any = userData.id;
                    const userMembership: any = userData.membership;
                    const username: any = userData.username;

                    dataLayerObject.userId = userId;
                    dataLayerObject.membership = userMembership;
                    dataLayerObject.username = username;
                    
                    //Push Data Layer
                    this._eventTrackingService.push(dataLayerObject);

                    subscription.unsubscribe();
                },
                (response: any) => {
                    this.loaderService.hideLoader(loader);

                    callback(response); // coming from hare

                    subscription.unsubscribe();
                },
            );

        return;
    }

    public updateUserData(data: any, callback: Function = () => { }) {
        return new Promise<any>((resolve, reject) => {
            const loader = this.loaderService.showLoader();

            const batchedRequests: Array<BatchRequestStructure> = new Array(),
                performedRequests: Array<{
                    requestName: string;
                    requestIndex: number;
                }> = new Array();

            batchedRequests.push(
                this.sdkBatchRequestService.createBatchObject(
                    'POST',
                    'user',
                    {},
                    data,
                ),
            );
            performedRequests.push({
                requestName: 'updateUserData',
                requestIndex: 0,
            });

            batchedRequests.push(
                this.sdkBatchRequestService.createBatchObject('GET', 'user', {
                    expand: ['all.all.all.all'],
                    'paging[limit]': '9999',
                }),
            );
            performedRequests.push({
                requestName: 'fetchUserData',
                requestIndex: 1,
            });

            this.sdkBatchRequestService
                .makeBatchRequest(
                    { requests: batchedRequests },
                    performedRequests,
                )
                .then(async (response) => {
                    // Check batch error.
                    if (response.error) {
                        this.loaderService.hideLoader(loader);

                        this.errorHandlingService.validateResponse(response);
                        callback(response);
                        reject(response);
                        return;
                    }

                    // Check every response for error.
                    if (
                        response.responses &&
                        Array.isArray(response.responses) &&
                        response.responses.length
                    ) {
                        const batchResponses = response.responses;
                        const error = batchResponses.find((res) => res.error);

                        if (error) {
                            this.loaderService.hideLoader(loader);
                            this.errorHandlingService.validateResponse(
                                response,
                            );
                            callback(response);
                            reject(response);
                            return;
                        }
                    }

                    const updatedUserData = response.responses[1];

                    const authRestrictions = await this.authRestrictions();

                    const bookConfiguration = this.configurationService.getConfigurationSettings()?.book;
                    let isAccountMenuHidden = false;

                    if (bookConfiguration?.settings?.hide_accounts_menu) {
                        isAccountMenuHidden = true;
                    }

                    if (!isAccountMenuHidden && authRestrictions !== 'anonym_auth' && updatedUserData.data.type_id !== 1) {

                        // Set user data
                        this.userDataProvider.setUserData(updatedUserData.data);

                        //NEW ACC MENU
                        this.buildAccountsMenu();

                        // Set user applications
                        this.setUserApplications();

                        this.loaderService.hideLoader(loader);

                        resolve(updatedUserData);
                        callback(updatedUserData);

                        // Get accounts menu config
                        this.fetchAccountsMenuConfig()
                            .then(
                                (accountsMenuConfig: any) => {
                                    // Success
                                    // Set accounts menu config
                                    // this.setAccountsMenuConfig(
                                    //     accountsMenuConfig,
                                    // );

                                    // Set user data
                                    // this.userDataProvider.setUserData(updatedUserData.data);

                                    // Set user applications
                                    // this.setUserApplications();

                                    // this.loaderService.hideLoader(loader);

                                    // resolve(updatedUserData);
                                    // callback(updatedUserData);
                                },
                                () => {
                                    // Error
                                    // Set user data
                                    // this.userDataProvider.setUserData(updatedUserData.data);

                                    // Set user applications
                                    // this.setUserApplications();

                                    // this.loaderService.hideLoader(loader);

                                    // resolve(updatedUserData);
                                    // callback(updatedUserData);
                                },
                            )
                            .catch((error) => {
                                // Set user data
                                // this.userDataProvider.setUserData(updatedUserData.data);

                                // Set user applications
                                // this.setUserApplications();

                                // this.loaderService.hideLoader(loader);

                                // resolve(updatedUserData);
                                // callback(updatedUserData);
                            });
                    } else {
                        // Set user data
                        this.userDataProvider.setUserData(updatedUserData.data);

                        // Set user applications
                        this.setUserApplications();

                        this.loaderService.hideLoader(loader);

                        resolve(updatedUserData);
                        callback(updatedUserData);
                    }
                })
                .catch((error) => {
                    callback(error);
                    reject(error);
                    this.loaderService.hideLoader(loader);
                });
        });
    }

    public notificationPreferences(): Promise<void> {
        return new Promise((res) => {
            if (
                this.mainDataProvider.obfEnv &&
                this.mainDataProvider.obfEnv.additionalConfig &&
                this.mainDataProvider.obfEnv.additionalConfig.functionality &&
                !this.mainDataProvider.obfEnv.additionalConfig.functionality
                    .showNotificationPreference
            ) {
                res();
                return;
            }

            const userData: User = this.getUserData();
            if (userData && userData.preferences && userData.preferences.preferences_submitted === false) {
                this.popupModalsService.standardInfoPopup('', '', {
                    icon: '',
                    title: this._localizationProvider.getLocalizedText('offerPopupTitle'),
                    image: '',
                    description: this._localizationProvider.getLocalizedText('offerPopupDescription'),
                    buttons: {
                        xButton: {
                            action: 'close',
                        },
                        otherButtons: [
                            {
                                label: this._localizationProvider.getLocalizedText('offerPopupCancelButton'),
                                action: 'cancel',
                                type: 'secondary',
                                state: 'default',
                                size: 'normal',
                            },
                            {
                                label: this._localizationProvider.getLocalizedText('offerPopupConfirmButton'),
                                action: 'confirm',
                                type: 'primary',
                                state: 'default',
                                size: 'normal',
                            },
                        ],
                    },
                }).then(async (data) => {
                    if (data === 'close') {

                    } else {
                        const loader = this.loaderService.showLoader();

                        if (data === 'confirm') {
                            await this.updatePreferences({
                                // allow_mk_sms: true,
                                allow_mk_email: true,
                            }).catch((e) => { });
                        } else {
                            await this.updatePreferences({
                                // allow_mk_sms: false,
                                allow_mk_email: false,
                            }).catch((e) => { });
                        }

                        this.fetchUserData({ expand: ['all.all.all.all'], 'paging[limit]': '9999' }, () => {
                            res();
                            this.loaderService.hideLoader(loader);
                        });
                        return;
                    }

                    res();
                }).catch((dismissAction) => {
                    res();
                });
            } else {
                res();
            }
        });
    }

    /**
     * Create the address and in callback function argument will receive the created address object
     * @param callback
     */
    public createAddress(
        data: {
            address_line_one: string;
            address_line_two: string;
            postcode: string;
        },
        queryParams?: SDKQueryParams,
        callback?: Function,
    ) {
        return new Promise<any>((resolve, reject) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg6'));
            this.sdkClientService
                .createAddress(data, queryParams)
                .then(
                    (response: any) => {
                        this.loaderService.hideLoader(loader);
                        this.errorHandlingService.validateResponse(response);

                        if (!response.error) {
                            this.updateUserDataProperty(
                                'addresses',
                                response.data,
                            );

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            resolve(response.data);
                        } else {
                            this.loaderService.hideLoader(loader);
                            reject(response);
                        }

                    },
                    (response) => {
                        this.errorHandlingService.validateResponse(response);
                        // Error
                        reject(response);
                    },
                )
                .catch((error) => {
                    // Error
                    reject(error);
                });
        });
    }

    /**
     * Update the address and in callback function argument will receive the updated
     * @param callback
     */
    public updateAddress(
        id,
        data: {
            address_line_one: string;
            address_line_two: string;
            postcode: string;
        },
        queryParams: SDKQueryParams,
        callback?: Function,
    ) {
        return new Promise<any>((resolve, reject) => {
            this.sdkClientService
                .updateAddress(id, data, queryParams)
                .then(
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);

                        if (!response.error) {
                            this.updateUserDataProperty(
                                'addresses',
                                response.data,
                            );

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    },
                    (response) => {
                        this.errorHandlingService.validateResponse(response);
                        // Error
                        reject(response);
                    },
                )
                .catch((error) => {
                    // Error
                    reject();
                });
        });
    }

    public claimAccount(data: { email: string }) {
        return new Promise((res, rej) => {
            const loader = this.loaderService.showLoader();
            const subscription = from(this.sdkClientService.claimAccount(data)).pipe(
                finalize(() => {
                    this.loaderService.hideLoader(loader);
                }),
            ).subscribe(
                (serverResponse) => {
                    // serverResponse.validationCallback = this._errorHandlingService.validateResponse(serverResponse);

                    if (serverResponse && !serverResponse.error) {
                        res(serverResponse);
                    } else {
                        rej(serverResponse);
                    }

                    subscription.unsubscribe();
                },
                (error) => {
                    // error.validationCallback = this._errorHandlingService.validateResponse(error);
                    rej(error);
                    subscription.unsubscribe();
                },
            );

        });
    }

    /**
     * Update the address and in callback function argument will receive the updated
     * @param callback
     */
    public resetPassword(
        data: { email: string },
        queryParams: SDKQueryParams,
        callback?: Function,
    ) {
        const loader = this.loaderService.showLoader();
        const subscription = from(
            this.sdkAccountService.resetPassword(data, queryParams),
        ).subscribe(
            (response: any) => {
                this.errorHandlingService.validateResponse(response);

                if (!response.error) {
                    callback(response);
                }

                subscription.unsubscribe();

                this.loaderService.hideLoader(loader);
            },
            (response) => {
                subscription.unsubscribe();

                this.errorHandlingService.validateResponse(response);

                this.loaderService.hideLoader(loader);

                callback(response);
            },
        );

        return;
    }

    /** Create user phone or update existing one
     * @param {number} phoneId
     * @param {object} phoneData
     * @param {object} queryParams
     */
    public updatePhone(
        phoneId: number,
        phoneData: any,
        queryParams: SDKQueryParams,
    ) {
        return new Promise<any>((resolve, reject) => {
            this.sdkClientService
                .updatePhone(phoneId, phoneData, queryParams)
                .then(
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);

                        if (!response.error) {
                            this.updateUserDataProperty(
                                'phones',
                                response.data,
                            );

                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    },
                    (response) => {
                        this.errorHandlingService.validateResponse(response);
                        // Error
                        reject(response);
                    },
                )
                .catch((error) => {
                    // Error
                    reject(error);
                });
        });
    }

    public updatePreferences(
        preferenceObject,
        queryParams: SDKQueryParams = {},
    ) {
        return new Promise<any>((resolve, reject) => {
            this.sdkClientService
                .updatePreferences(preferenceObject, queryParams)
                .then((response) => {
                    this.errorHandlingService.validateResponse(response);

                    if (!response.error) {
                        resolve(response.data);
                    } else {
                        // Error
                        reject();
                    }
                })
                .catch((error) => {
                    // Error
                    reject();
                });
        });
    }

    public searchAddress(queryParams) {
        return new Promise<any>((resolve, reject) => {
            // const loader = this.loaderService.showLoader();
            // const queryParams: SDKQueryParams = { 'query[phrase]': address, 'query[field]': 'postcode', country_iso_code: 'CH' };
            // const queryParams: SDKQueryParams = { 'query[phrase]': address, 'scope': 'postcode_ch' };

            this.sdkClientService
                .searchAddress(queryParams)
                .then(
                    (response: any) => {
                        if (response !== null) {

                            this.errorHandlingService.validateResponse(response);
                            
                            if (!response.error) {
                                resolve(response.data);
                            }
                            
                            // this.loaderService.hideLoader(loader);
                        } else {
                            // request is disable by xrm when return null
                        }
                    },
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);
                        // this.loaderService.hideLoader(loader);

                        // Error
                        reject();  
                    },
                )
                .catch((error) => {
                    // this.loaderService.hideLoader(loader);

                    // Error
                    reject();
                });
        });
    }

    public getProfiles(queryParams?) {
        return new Promise<any>((resolve, reject) => {
            this.sdkClientService
                .getProfiles(queryParams)
                .then(
                    (response: any) => {
                        if (response !== null) {

                            this.errorHandlingService.validateResponse(response);
                            
                            if (!response.error) {
                                resolve(response.data);
                            }
                            
                            // this.loaderService.hideLoader(loader);
                        } else {
                            // request is disable by xrm when return null
                        }
                    },
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);
                        // this.loaderService.hideLoader(loader);

                        // Error
                        reject();  
                    },
                )
                .catch((error) => {
                    // this.loaderService.hideLoader(loader);

                    // Error
                    reject();
                });
        });
    }

    /**
     * Create new user phone
     * @param phoneData
     * @param queryParams
     */
    public createPhone(phoneData: any, queryParams: SDKQueryParams) {
        return new Promise<any>((resolve, reject) => {
            const loader = this.loaderService.showLoader();
            this.sdkClientService
            .createPhone(phoneData, queryParams)
            .then(
                (response: any) => {
                    this.loaderService.hideLoader(loader);
                    this.errorHandlingService.validateResponse(response);

                    if (!response.error) {
                        // Update user phones
                        const userData: any = this.userDataProvider.getUserData();

                        if (userData.phones && userData.phones.length) {
                            userData.phones.push(response.data);
                        } else {
                            Object.assign(userData, {
                                phones: [response.data],
                            });
                        }

                        this.userDataProvider.setUserData(userData);

                        resolve(response.data);
                    }
                },
                (response: any) => {
                    this.loaderService.hideLoader(loader);
                    this.errorHandlingService.validateResponse(response);
                    // Error
                    reject(response);
                },  
            )
            .catch((error) => {
                // Error
                reject(error);
            });
        });
    }

    /**
     * Extract user address by id
     * @param id address id
     */
    public getUserAddressById(id) {
        const userData: User = this.getUserData();

        if (typeof id === 'string') id = parseInt(id);

        return userData?.addresses?.find((el) => el.id === id);
    }

    /**
     * Get user payment methods by type
     * @param {string} paymentMethodType
     */
    public getUserPaymentMethods(paymentMethodType: string) {
        const userData: User = this.getUserData();

        let filteredPaymentMethods: Array<any> = [],
            userPaymentMethods: Array<any> = userData.paymethods;

        if (userPaymentMethods && userPaymentMethods.length) {
            filteredPaymentMethods = userPaymentMethods.filter(
                (paymentMethod) => {
                    return paymentMethod.type === paymentMethodType;
                },
            );
        }

        return filteredPaymentMethods;
    }

    /**
     * Fetch membership details
     * @param bookingId
     * @param queryParams
     */
    public fetchMembershipDetails(bookingId: string, queryParams: SDKQueryParams) {
        return new Promise<any>((resolve, reject) => {
            const loader = this.loaderService.showLoader();
            this.sdkClientService
                .fetchMembershipDetails(bookingId, queryParams)
                .then(
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);

                        if (!response.error) {
                            resolve(response.data);
                        }

                        this.loaderService.hideLoader(loader);
                    },
                    (response: any) => {
                        this.errorHandlingService.validateResponse(response);
                        this.loaderService.hideLoader(loader);

                        // Error
                        reject();  
                    },
                )
                .catch((error) => {
                    this.loaderService.hideLoader(loader);

                    // Error
                    reject();
                });
        });
    }

    public authRestrictions(): Promise<"required_auth" | "not_required_auth" | "anonym_auth"> {
        return new Promise((resolve: Function, reject: Function) => {
            this.configurationService.getConfiguration()
                .then(
                    (configuration: any) => {
                        if (
                            configuration?.book?.settings?.hasOwnProperty('disable_auth') && configuration.book?.settings.disable_auth ||
                            !configuration?.book?.hasOwnProperty('disable_auth') && configuration && configuration.hasOwnProperty('disable_accounts') && configuration.disable_accounts
                        
                        ) {
                            resolve('anonym_auth');
                        } else if (
                            configuration && configuration.hasOwnProperty('login_step_optional') && configuration.login_step_optional === true ||
                            configuration && configuration.book?.settings?.login_step_optional
                        ) {
                            resolve('not_required_auth');
                        } else {
                            resolve('required_auth');
                        }
                    },
                );
        });
    }

    /**
     * Retrieve observable for user data
     */
    public getUserDataSubscription(): BehaviorSubject<User> {
        return this.userDataProvider.getSubscription();
    }

    /**
     * Get user phone - default one or first one
     */
    public getUserPhone(id?: any): Phone {
        const userData: User = this.getUserData();

        if (userData && userData.phones && userData.phones.length) {
            if (id) {
                for (let index = 0; index < userData.phones.length; index++) {
                    const phone = userData.phones[index];
                    if (phone.id.toString() === id) {
                        return phone;
                    }
                }
    
            } else {
                for (let index = 0; index < userData.phones.length; index++) {
                    const phone = userData.phones[index];
                    if (phone.default) {
                        return phone;
                    }
                }
    
                return userData.phones[0];
            }
            
        }

        return null;
    }

    /**
     * Web Accounts user menu config getter
     */
    public getAccountsMenuConfig() {
        return this.userDataProvider.getAccountsMenuConfig();
    }

    /**
     * Web Accounts user menu config setter
     */
    public setAccountsMenuConfig(accountsMenuConfig: any) {
        // *** Send to changed accountsConfig.
        if (environment.integrate) {
            window._FSAccountMenu(accountsMenuConfig);
        } else {
            this.postMessageService.sendState(
                'auth',
                accountsMenuConfig,
            );
        }

        this.userDataProvider.setAccountsMenuConfig(accountsMenuConfig);
    }

    /**
     * Check if user is logged in
     */
    public isUserLogged(): boolean {
        return this.getUserData() && this.getUserData().type_id !== 1 ? true : false;
    }

    /**
     * User initial authentication status getter
     */
    public didUserCameLogged(): boolean {
        return this.userDataProvider.getInitialAuthStatus();
    }

    /**
     * Sets user initial authentication status (did he came logged or not)
     * @param flag
     */
    public setInitialAuthStatus(flag: boolean): void {
        this.userDataProvider.setInitialAuthStatus(flag);
    }

    /**
     * User data resetter
     */
    public resetUserData(): void {
        this.userDataProvider.resetUserData();
    }

    /**
     * Set useer applications
     */
    public setUserApplications(): void {
        let deviceInfo: any = this.deviceService.getDeviceInfo(),
            userData: any = this.getUserData(),
            applicationExists = false;

        if (deviceInfo && userData && userData.user_applications) {
            applicationExists = userData.user_applications.filter(
                (application: any) => {
                    return (
                        application.device === deviceInfo.device &&
                        application.browser === deviceInfo.browser
                    );
                },
            ).length
                ? true
                : false;
        }

        // Push to user applications only if device is never used
        if (deviceInfo && !applicationExists) {
            const obfOptions: ObfOptions = this.mainDataProvider.getResourceObfOptions();

            // Build user application object
            const userApplicationObject: UserApplications = {
                os: deviceInfo.os ? deviceInfo.os : 'N/A',
                os_version: deviceInfo.os_version
                    ? deviceInfo.os_version
                    : 'N/A',
                browser: deviceInfo.browser ? deviceInfo.browser : 'N/A',
                browser_version: deviceInfo.browser_version
                    ? deviceInfo.browser_version
                    : 'N/A',
                browser_resolution: `${window.innerWidth}x${window.innerHeight}`,
                device: deviceInfo.device ? deviceInfo.device : 'N/A',
                device_resolution: `${window.screen.width}x${window.screen.height}`,
                profile:
                    obfOptions?.profile_id
                        ? obfOptions.profile_id : obfOptions?.profile
                        ? obfOptions.profile
                        : 'N/A',
                guid: genLongGUID(),
            };

            this.sdkSharedService
                .setUserApplications(userApplicationObject)
                .then(
                    (response: any) => {
                        // Success
                        if (!response.error) {
                            // User applications successfully updated!
                        }
                    },
                    (response: any) => {
                        // Error
                    },
                );
        }
    }

    /**
     * Get menu from Web Accounts
     */
    public fetchAccountsMenuConfig(): Promise<any> {

        const sid = this.mainDataProvider.getSessionIdObserver()?.getValue();
        const currentLanguage = this._localizationProvider.getCurrentLanguage();
        let csid = null;
        if (sid) csid = this.cryptoService.encryptData(sid, this.mainDataProvider.obfEnv.cookieKey);
        return new Promise((resolve: Function, reject: Function) => {
            
            this.sdkAuthService
                .getAccountsMenu(csid, currentLanguage)
                .then(
                    (response) => {
                        // Success
                        const errorKeyword = 'var message = false';

                        if (response && response.indexOf(errorKeyword) === -1) {
                            // Keywords for string parsing
                            let firstKeyword = 'function()',
                                secondKeyword = '()',
                                extractedFunction: string = response.substring(
                                    response.indexOf(firstKeyword),
                                    response.lastIndexOf(secondKeyword) - 1,
                                ), // Extract function from HTML string
                                getAccountsMenu: Function | null = null;
                            try {
                                // Replace row, which sends post message with return statement
                                extractedFunction = extractedFunction.replace(
                                    'window.top.postMessage({accountsConfig: message},\'*\');',
                                    'return message;',
                                );

                                getAccountsMenu = new Function(
                                    'return ' + extractedFunction,
                                )();
                            } catch (e) {
                                const errorModel = {
                                    reason: 'fetchAccountsMenuConfig error',
                                    message: e,
                                    response,
                                };

                                this.errorReportingService.report(
                                    {
                                        emailErrors: [errorModel],
                                    },
                                    false,
                                    'Authentication',
                                );
                            }

                            if (typeof getAccountsMenu === 'function') {
                                const accountsMenu: any = getAccountsMenu(csid);

                                const accountsMenuConfig: any = {
                                    userId: accountsMenu.id,
                                    member: accountsMenu.member,
                                    userReferralLink: accountsMenu.userReferralLink
                                        ? accountsMenu.userReferralLink
                                        : '',
                                    // avatarUrl: accountsMenu.avatar
                                    //     ? accountsMenu.avatar + '/64/64'
                                    //     : '',
                                    waLinks: accountsMenu.links,
                                    clientName: accountsMenu.clientName,
                                    defaultDomain: accountsMenu.domain,
                                    assets: accountsMenu.assets,
                                };

                                resolve(accountsMenuConfig);
                            } else {
                                // Problem with parsing the html string response

                                reject();
                            }
                        } else {

                            reject();
                        }
                    },
                    (response) => {
                        // Error

                        reject();
                    },
                )
                .catch((error) => {
                    reject();
                });
        });
    }

    /**
     * Add recently created object or update existing ones in current user data
     * @param {object} newAddress
     */
    private updateUserDataProperty(propertyName: string, newObject: Address) {
        const arrayPropertyNames: Array<string> = [
            'addresses',
            'phones',
            'paymethods',
        ];

        const userData: User = this.userDataProvider.getUserData();

        if (arrayPropertyNames.indexOf(propertyName) !== -1) {
            if (userData.hasOwnProperty(propertyName)) {
                userData[propertyName] = userData[propertyName] || new Array();
            }

            // If there was address creation - add it. Otherwise update existing one
            if (userData[propertyName]) {
                if (userData[propertyName].length) {
                    let isObjectUpdated = false;

                    for (
                        let userDataPropertyIndex = 0;
                        userDataPropertyIndex < userData[propertyName].length;
                        userDataPropertyIndex++
                    ) {
                        if (
                            newObject.id ===
                            userData[propertyName][userDataPropertyIndex].id
                        ) {
                            userData[propertyName][
                                userDataPropertyIndex
                            ] = newObject;

                            // Indicate that action is updating object, not adding new one
                            isObjectUpdated = true;
                        }
                    }

                    if (!isObjectUpdated) {
                        userData[propertyName].push(newObject);
                    }
                } else {
                    // User has no addresses - only add the new one
                    userData[propertyName].push(newObject);
                }

                if (propertyName === 'phones') {
                    userData[propertyName] = userData[propertyName].map((phone) => {
                        if (newObject.default === true && phone.id !== newObject.id) {
                            phone.default = false;
                            return phone;
                        }

                        return phone;
                    });
                }

            }
        } else {
            // Non array property
            userData[propertyName] = newObject;
        }

        // Notify other components for change in user data
        this.userDataProvider.setUserData(userData);
    }

    /**
     * Check for membership
     */
    public isMembershipPurchased(): boolean {
        if (this.orderData && this.orderData.activeBooking && this.orderData.activeBooking.getValue()) {
            const activeBooking: any = this.orderData.activeBooking.getValue() ? this.orderData.activeBooking.getValue().get() : null;

            if (activeBooking && activeBooking.price && activeBooking.price.price_breakdown && activeBooking.price.price_breakdown.length) {

                if (
                    activeBooking &&
                    activeBooking.price &&
                    activeBooking.price.price_breakdown &&
                    activeBooking.price.price_breakdown.length
                ) {
                    const hasPurchasedMembership: boolean = activeBooking.price.price_breakdown.find(
                        (el) => {
                            return el.type === 'membership';
                        },
                    );

                    if (hasPurchasedMembership) {
                        return true;
                    }
                }
            }

            return false;
        }

        return false;
    }

    /**
     * Check all possible conditions for user_type
     * @param deal
     */
    public checkClientTypeConditions(clientTypesToCheck: string): boolean {
        const clientType: string = this.getClientType();

        return (
            !clientTypesToCheck ||
            clientTypesToCheck.indexOf('all') !== -1 ||
            (clientTypesToCheck.indexOf('member') !== -1 &&
                clientType === 'member') ||
            (clientTypesToCheck.indexOf('regular') !== -1 &&
                clientType === 'regular') ||
            (clientTypesToCheck.indexOf('newmember') !== -1 &&
                clientType === 'newmember')
        );
    }

    /**
     * Sets LoginAndRegisterComponent initial tab
     * @param flag
     */
    public setLoginModalDefaultTab(tabName: string): void {
        this.userDataProvider.setLoginModalDefaultTabName(tabName);
    }

    /**
     * LoginAndRegisterComponent default tab getter
     */
    public getLoginModalDefaultTab(): string {
        return this.userDataProvider.getLoginModalDefaultTabName();
    }

    /**
     * Set client type in provider
     * @param clientType
     */
    public setClientType(clientType: string): void {
        this.userDataProvider.setClientType(clientType);
    }

    /**
     * Get client type from provider
     */
    public getClientType(): string {
        return this.userDataProvider.getClientType();
    }

    /**
     * Anonymous session flag getter
     */
    public isAnonymSession(): Boolean {
        return this.userDataProvider.isAnonymSession();
    }

    /**
     * Anonymous session flag setter
     */
    public setAnonymSession(flag: Boolean): void {
        this.userDataProvider.setAnonymSession(flag);
    }

    /**
     * Checks all mandatory user data fields needed for on_summary step
     * @returns
     */
    public isAllUserDataCollected(): boolean {
        if (!this.isUserLogged()) { return false; }

        const userData: any = this.getUserData();

        let defaultPhone: any = null;

        // set default phone
        if (userData.phones && Array.isArray(userData.phones) && userData.phones.length) {
            defaultPhone = userData.phones.find((el) => el.default);
            defaultPhone = defaultPhone ? defaultPhone : userData.phones[0];
        }

        const userPhones: Array<any> = defaultPhone ? [defaultPhone] : [];

        const result = userData
            && userData.first_name
            && userData.first_name.length
            && userData.last_name
            && userData.last_name.length
            && userData.username
            && userPhones
            && userPhones.length
            ? true
            : false;

        return result;
    }

    public buildAccountsMenu() {

        //OLD
        // iconClass : "page_icon_dashboard"
        // iconUrl : "https://files.dxr.cloud/ophU0f84omVWJiIKKpX5j6TVv3SnMOVGgnB0okO3p2nLjXdid3sM85z1.svg" 
        // menuName : "dashboard" 
        // pageName : "Dashboard" 
        // pageUrl : "https://accounts-dev.1dxr.com/dashboard" 
        // showOnExternal : true

        //NEW
        // count : 0 
        // icon : "page_icon_dashboard" 
        // icon_url : "https://files.dxr.cloud/ophU0f84omVWJiIKKpX5j6TVv3SnMOVGgnB0okO3p2nLjXdid3sM85z1.svg" 
        // name : "dashboard" 
        // path : "/dashboard" 
        // requiredModules : [] 
        // sort : 100 
        // tag : "" 
        // title : "Dashboard"


        let accMenu = null;

        const obfOptions: ObfOptions = this.mainDataProvider.getResourceObfOptions();
        const configuration = this.configurationService.getConfigurationSettings();
        const accountConfiguration = configuration?.account;
        let configurationMenu = accountConfiguration?.components?.menu?.items;
        const userData: any = this.getUserData();

        let waLinksMenu = [];

        if (!configurationMenu) {
            // BACKUP menu - if profile doesnt has configured one

            configurationMenu = [
                {
                   name:"dashboard",
                   title:"Dashboard",
                   icon:"page_icon_dashboard",
                   icon_url:"",
                   path:"/dashboard",
                   count:0,
                   requiredModules:[
                      
                   ],
                   sort:100,
                   tag:""
                },
                {
                   name:"bookings",
                   title:"Bookings",
                   icon:"page_icon_bookings",
                   icon_url:"",
                   path:"/bookings",
                   count:0,
                   requiredModules:[
                      
                   ],
                   sort:200,
                   tag:"counter"
                },
                {
                   name:"membership",
                   title:"Membership",
                   icon:"page_icon_club",
                   icon_url:"",
                   path:"/membership-club",
                   count:0,
                   requiredModules:[
                      "membership"
                   ],
                   sort:300,
                   tag:""
                },
                {
                   name:"credits",
                   title:"Credits",
                   icon:"page_icon_credits",
                   icon_url:"",
                   path:"/credit-activity",
                   count:0,
                   requiredModules:[
                      
                   ],
                   sort:260,
                   tag:""
                },
                {
                   name:"referral",
                   title:"Invite and earn",
                   icon:"page_icon_invite_and_earn",
                   icon_url:"",
                   path:"/invite-and-earn",
                   count:0,
                   requiredModules:[
                      
                   ],
                   sort:270,
                   tag:""
                },
                {
                   name:"treats",
                   title:"Deals",
                   icon:"page_icon_treats",
                   icon_url:"",
                   path:"/treats",
                   count:0,
                   requiredModules:[
                      "treats"
                   ],
                   sort:600,
                   tag:""
                },
                {
                   name:"notifications",
                   title:"Notifications",
                   icon:"page_icon_notifications",
                   icon_url:"",
                   path:"/notifications",
                   count:0,
                   requiredModules:[
                      
                   ],
                   sort:300,
                   tag:"counter"
                },
               
                 {
                      name:"settings",
                      title:"Settings",
                      icon:"page_icon_settings",
                      icon_url:"",
                      path:"/account-settings",
                      count:0,
                      requiredModules:[
                         
                      ],
                      sort:800,
                      tag:""
                   },
                   {
                      name:"logout",
                      title:"Logout",
                      icon:"logout",
                      icon_url:"",
                      path:"",
                      action:"logout",
                      count:0,
                      requiredModules:[
                         
                      ],
                      sort:900,
                      tag:""
                   }
                ]
        }

        // Map configation menu
        if (configurationMenu && configuration) {

            const loadedModules = configuration?.application?.settings?.modules;

            if (loadedModules) {
                // Reduce menu items based on required modules
                configurationMenu = configurationMenu.filter(
                    (navigationItem) => {
                        if (
                            navigationItem.requiredModules &&
                            navigationItem.requiredModules.length
                        ) {
                            const containsAll = navigationItem.requiredModules.every((m) =>
                                loadedModules.includes(m)
                            );
            
                            return containsAll;
                        }
                        return true;
                    }
                )   
            }

            waLinksMenu = configurationMenu.map(
                (link) => {
                    return {
                        iconClass : link.icon,
                        iconUrl : link.icon_url,
                        menuName : link.name,
                        pageName : link.title,
                        pageUrl : obfOptions?.accounts_url + link.path?.substring(1),
                        showOnExternal : true
                    }
                }
            );
        }

        accMenu = {
            userId: userData?.id,
            member: userData?.membership ? true : false,
            userReferralLink: userData?.referral_code,
            avatarUrl: userData?.avatar?.url,
            waLinks: waLinksMenu && waLinksMenu.length ? waLinksMenu : [],
            clientName: userData?.first_name + ' ' + userData?.last_name,
            defaultDomain: obfOptions.accounts_url,
            assets: obfOptions.accounts_url ? { styleLink: obfOptions.accounts_url + "css/webaccounts.css" } : {},
            // assets: null
        }

        this.setAccountsMenuConfig(accMenu);

    }
}
