import { Injectable } from '@angular/core';
import { Booking } from '@models/booking';
import { BookingTransactionModel } from '@models/booking-transaction-model';
import { Position } from '@models/position';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChoicesPositions } from 'src/app/constants/choice';
import { positionsConfig } from 'src/app/constants/obf-paths';

@Injectable()
export class OrderData {
    public bookings: Array<BookingTransactionModel> = [];
    public activeBooking: BehaviorSubject<BookingTransactionModel | null>;
    public refreshAvailability: BehaviorSubject<boolean>;
    public openMembershipModal: BehaviorSubject<boolean>;
    public openExitModal: BehaviorSubject<boolean>;
    private currentStep: Position = null;
    private restoreInitStep: { position: any, index: any } = {
        position: null,
        index: null
    };
    private serviceRedirectData = {
        flag: false,
        oldServiceTitle: '',
        oldServiceId: null
    };
    public redirect = false;
    public skipStep = false;
    public bookingType: 'edit' | 'reschedule' | 'normal' | 'rebook' | 'restore' | 'procrossbooking' = 'normal';
    public coverageMessage = '';
    public noCoverageObject: any = null;
    public confirmationMessage = '';
    public formOpen = false;
    public isCardPayment = false;
    public disableRestorePreviousSteps: boolean = false;
    public exitStep: { position: any, index: any, currentStep: any, shouldDisableBackButtons: boolean } = {
        position: null,
        index: null,
        currentStep: null,
        shouldDisableBackButtons: false
    };

    private guestSession = false;

    private selectServiceMethod = '';

    public noPrice: BehaviorSubject<boolean>;
    public disableSkipInitSteps: BehaviorSubject<boolean>;
    private noInitStep = false;

    public constructor(
        private _eventTrackingService: EventTrackingService
    ) {
        this.refreshAvailability = new BehaviorSubject(false);
        this.openMembershipModal = new BehaviorSubject(false);
        this.openExitModal = new BehaviorSubject(false);
        this.activeBooking = new BehaviorSubject(null);
        this.noPrice = new BehaviorSubject(false);
        this.disableSkipInitSteps = new BehaviorSubject(false);
    }

    // public setResourceActiveBooking(newActiveBooking) {
    //     this.activeBooking.next(newActiveBooking);
    // }

    public getResourceActiveBooking(): BookingTransactionModel {
        return this.activeBooking.getValue();
    }

    public getResourceObserverActiveBooking(): Observable<BookingTransactionModel> {
        return this.activeBooking;
    }

    public isNoPrice() {
        return this.noPrice.getValue();
    }

    public setHasInitStepFlag(flag) {
        this.noInitStep = flag;
    }

    public hasInitStep() {
        return !!this.noInitStep;
    }

    /**
     * Create and
     * @param data Pass the booking transaction from XRM
     */
    public addNewBooking(data: Booking): void {

        // Reset sent data layer events list
        this._eventTrackingService.resetSentEvents();
        
        this.bookings = [];
        this.bookings.push(new BookingTransactionModel(data));
        if (this.bookings.length === 1) {
            this.activeBooking.next(this.bookings[0]);
        }
    }

    public getGuestSession() {
        return this.guestSession;
    }

    public setGuestSession(flag) {
        this.guestSession = flag;
    }


    public setSelectServiceMethod(method) {
        this.selectServiceMethod = method;
    }

    public getSelectServiceMethod() {

        const method = this.selectServiceMethod;
        //reset method after getter
        this.setSelectServiceMethod('');

        return method;
    }

    /**
     * When don't have active transaction return null.
     * When have active transaction an the transaction have state will return the transaction state.
     * When have active transaction and don't have set state will return state base on what is the data inside. From configurator to availability max.
     */
    public getActiveTransactionProgress(): { position: ChoicesPositions, choice_id?: number | null } | null {
        const activeTransaction: BookingTransactionModel | null = this.getResourceActiveBooking();
        const state = {
            choice_id: null,
            position: null,
        };

        if (activeTransaction) {
            const booking = activeTransaction.get();
            if (
                this.bookingType === 'restore' ||
                this.bookingType === 'procrossbooking' ||
                this.bookingType === 'normal' ||
                this.bookingType === 'edit' ||
                this.bookingType === 'rebook'
            ) {

                if (booking?.state?.position) {
                    return { ...state, ...booking.state };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.Configurator).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.Configurator)[0].id,
                        position: ChoicesPositions.Configurator,
                    };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.BeforeAvailability).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.BeforeAvailability)[0].id,
                        position: ChoicesPositions.BeforeAvailability,
                    };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.OnAvailability).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.OnAvailability)[0].id,
                        position: ChoicesPositions.OnAvailability,
                    };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.AfterAvailability).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.AfterAvailability)[0].id,
                        position: ChoicesPositions.AfterAvailability,
                    };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.BeforeSummary).length) {
                    return {
                        choice_id: null, // still we don't have group choice so we use old logic there for visualize 
                        position: ChoicesPositions.BeforeSummary,
                    };
                }

                return {
                    choice_id: null,
                    position: ChoicesPositions.OnSummary,
                };

            } else if (this.bookingType === 'reschedule') {

                if (booking?.state?.position) {
                    return { ...state, ...booking.state };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.BeforeAvailability).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.BeforeAvailability)[0].id,
                        position: ChoicesPositions.BeforeAvailability,
                    };
                }

                if (activeTransaction.getTransactionChoices(ChoicesPositions.OnAvailability).length) {
                    return {
                        choice_id: activeTransaction.getTransactionChoices(ChoicesPositions.OnAvailability)[0].id,
                        position: ChoicesPositions.OnAvailability,
                    };
                }

            }

        } else {
            return null;
        }
    }

    /**
     * @param {string} id
     */
    public getByBookingId(id: string): BookingTransactionModel {
        const index = this.findIndex(id);
        return this.bookings[index];
    }

    /**
     * Choice new Active booking  by given transaction id
     * @param {string} id
     */
    public selectActiveBookingByID(id: string): boolean {
        this.activeBooking.next(this.getByBookingId(id));
        if (this.activeBooking !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    /**
     *
     * @param {string} id
     * @param data
     */
    public updateBooking(id: string, data: Booking): boolean {
        const index = this.findIndex(id);

        if (index !== -1) {
            this.bookings[index].setData(data);
            this.activeBooking.next(this.bookings[index]);

            return true;
        }
        return false;
    }

    /**
     * Return index of the booking transaction
     * @param id
     */
    private findIndex(id: string) {
        return this.bookings.findIndex((el) => {
            return el.get().id === id;
        });
    }

    /**
     * Resets current active booking
     */
    public resetActiveBooking(): void {
        this.bookingType = 'normal';
        this.activeBooking.next(null);
    }

    /**
     * Current step setter
     * @param {Position} step new current step
     */
    public setCurrentStep(step: Position): void {
        this.currentStep = step ? step : this.currentStep;
    }

    /**
     * Current step getter
     */
    public getCurrentStep(): Position {
        return this.currentStep;
    }

    public setExitStepConfig(position, currentStep) {

        const exitStepIndex = positionsConfig.findIndex(i=> i.position === position);

        if (!position) return;

        this.exitStep = {
            position: position,
            index: exitStepIndex,
            currentStep: currentStep,
            shouldDisableBackButtons: exitStepIndex === currentStep?.index
        };
    }

    public getExitStepConfig() {
        return this.exitStep;
    }

    /**
     * Current Form State
     */
    public getFormState(): boolean {
        return this.formOpen;
    }

    /**
     * Current Form State setter
     * @param {number} open // state
     */
    public setFormState(open: boolean): void {
        this.formOpen = open;
    }

    public setServiceRedirectData(flag, oldServiceData?) {
        this.serviceRedirectData.flag = flag;
        this.serviceRedirectData.oldServiceId = flag ? oldServiceData?.id : null;
        this.serviceRedirectData.oldServiceTitle = flag ? oldServiceData?.title : '';
    }

    public getServiceRedirectData() {
        return this.serviceRedirectData;
    }

}
