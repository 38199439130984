import { Injectable } from '@angular/core';
import { Booking } from '@models/booking';
import { Choice } from '@models/choice';
import { ChoiceItem } from '@models/choice-item';
import { DependencyMethod } from '@models/dependency-method';
import { Phone } from '@models/phone';
import { ServerResponse } from '@models/server-response';
import { Service } from '@models/service';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { SDKSystemService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKSystemService';
import moment from 'moment-mini';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChoicesPositions } from '../../constants/choice';
import { findChoiceItemByType, getBingCookieId, getGoogleCookieId, getMetaCookieId, getUrlVars, simplifyChoices } from '../../helpers/global-functions';
import { isValidJson } from '../../helpers/helper-functions';
import { ExceptionsService } from '../exceptions.service';
import { LoaderService } from '../loader.service';
import { SDKQueryParams } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { SDKBookingProcessService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKBookingProcessService';
import { SDKClientService } from './../../../../lib/xrm-sdk/src/lib/Services/SDKClientService';
import { RequestConfig } from './../../models/request-config';
import { ConfigurationData } from './../../providers/xrm/configuration-data.provider';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { OrderData } from './../../providers/xrm/order-data.provider';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { CacheService } from './../cache.service';
import { ErrorHandlingService } from './../errors/error-handling.service';
import { PostMessageService } from './../post-message.service';
import { ConfigurationService } from './configuration.service';
import { ServicesService } from './services.service';
import { UserDataService } from './user-data.service';
import { XRMApiCommunicatorService } from './xrmapi-communicator.service';
import { LocalizationProvider } from '@providers/localization.provider';
import { NavigationService } from '@services/navigation.service';
import { ModalsService } from '@services/modals.service';
import { InitConfigDataService } from './init-config-data.service';

@Injectable({
    providedIn: 'root',
})

export class BookingTransactionsService {
    public promocodeWarning: BehaviorSubject<any>;
    private promocodeUpdate: BehaviorSubject<Boolean>;

    private xrmConnection: SDKBookingProcessService;
    private SDKClientsService: SDKClientService;
    private SDKSystemService: SDKSystemService;

    private apiPaths: { [key: string]: string } = {
        leaveReasons: 'client/leave_reasons',
    };

    constructor(
        private _loaderService: LoaderService,
        private _orderData: OrderData,
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _mainDataProvider: MainDataProvider,
        private _authenticationService: AuthenticationService,
        private _errorHandlingService: ErrorHandlingService,
        private _servicesService: ServicesService,
        private _exceptionsService: ExceptionsService,
        private _userDataService: UserDataService,
        private _cacheService: CacheService,
        private _configurationService: ConfigurationService,
        private _configurationDataProvider: ConfigurationData,
        private _eventTrackingService: EventTrackingService,
        private _logger: NGXLogger,
        private _postMessageService: PostMessageService,
        private _localizationProvider: LocalizationProvider,
        private _navigationService: NavigationService,
        private _modalsService: ModalsService,
        private _initConfigDataService: InitConfigDataService,

    ) {
        this.promocodeWarning = new BehaviorSubject(false);
        this.promocodeUpdate = new BehaviorSubject(null);
        this.getXrmConnection();

        const errorHandlerDepMethods: Array<DependencyMethod> = [
            {
                name: 'logout',
                call: this._authenticationService.logout.bind(this._authenticationService),
            },
            {
                name: 'createTransaction',
                call: this.createTransaction.bind(this),
            },
            {
                name: 'getTransactionAddress',
                call: this.getTransactionAddress.bind(this),
            },
            {
                name: 'fetchTransaction',
                call: this.fetchTransaction.bind(this),
            },
        ];

        // Provide methods needed for ErrorHandlingService in order to let it take the proper action
        this._errorHandlingService.setDepMethods(errorHandlerDepMethods);

        this._navigationService.successNavigation.subscribe((data) => {
            const obfOptions = this._mainDataProvider.getResourceObfOptions();

            if (obfOptions?.exit_position) {
                this._orderData.setExitStepConfig(obfOptions.exit_position, data?.currentPosition);
            }

        });

    }

    public lastActiveTransactionStateUpdate = null;
    public updateBookingTransactionState(currentPosition, prevPosition): Promise<any> {
        return new Promise((res, rej) => {
            if (this._orderData.activeBooking.getValue()) {
                const sendData: any = {};
                const currentTransaction = this.getTransaction();
                if (!currentTransaction) {
                    res(true);
                    return;
                }

                if (
                    prevPosition === null ||
                    (currentPosition.position !== prevPosition.position && prevPosition.index < currentPosition.index ||
                        this.lastActiveTransactionStateUpdate && currentTransaction && this.lastActiveTransactionStateUpdate.id !== currentTransaction.id)
                    // && currentPosition.position !== ChoicesPositions.Confirmation
                ) {
                    sendData.state = {
                        position: currentPosition.position,
                    };

                    this.updateTransaction(
                        currentTransaction.id,
                        sendData,
                        { expand: ['all.all.all.all'] },
                        (validationResult: any, response: any) => {
                            const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                            if (validationResult.success) {
                                this.lastActiveTransactionStateUpdate = currentTransaction;
                            } else {
                                // noting
                            }

                            if (validationCallback) {
                                res({ callbackMethod: validationCallback });
                            } else {
                                res(true);
                            }
                        },
                        false);
                } else {
                    res(true);
                }
            } else {
                res(true);
            }

        });
    }

    private getXrmConnection() {
        if (!this.xrmConnection) {
            this.xrmConnection = this._XRMApiCommunicatorService.getBookingProcessService();
        }

        if (!this.SDKClientsService) {
            this.SDKClientsService = this._XRMApiCommunicatorService.getClientService();
        }

        if (!this.SDKSystemService) {
            this.SDKSystemService = this._XRMApiCommunicatorService.getSystemService();
        }
    }

    /**
     * Get transaction by ID
     * response is add to OrderData provider and push createEvent in bookingTransaction
     * in the end function call the callback passing the response
     * @param {string} transactionId
     * @param {Function} callback
     */
    public fetchTransaction(transactionId: string, queryParams?: SDKQueryParams, callback?: Function, sendCreateState: boolean = false) {
        this.getXrmConnection();
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg15'));
        let success: Boolean = false;

        const subscription = from(this.xrmConnection.fetchTransaction(transactionId, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (response && !response.error) {
                        success = true;

                        if (sendCreateState) {
                            this._postMessageService.sendState('booking-transaction-state', {
                                id: response.data.id,
                                service: {
                                    title: response.data.service.title,
                                },
                                state: 'restore',
                            });
                        }

                        this._orderData.addNewBooking(response.data);

                        if (typeof callback === 'function') {
                            const validationResult: any = {
                                success,
                            };

                            if (validationCallback) {
                                Object.assign(validationResult, { callbackMethod: validationCallback });
                            }

                            callback(validationResult);
                        }
                    }

                    subscription.unsubscribe();
                },
                (response) => {
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult);
                    }

                    subscription.unsubscribe();
                },
            );

        return;
    }

    /**
     * Create booking by passed service and init choice item
     * response is add to OrderData provider and push createEvent in bookingTransaction
     * in the end function call the callback passing the response
     * @param {object} data {service: {id:string,choices:[..]}}
     * @param {Function} callback
     */
    public async createTransaction(data: any, queryParams?: SDKQueryParams, callback?: Function) {

        const urlParams = getUrlVars();
        let itemsToPrefill = [];
        let obfOptions = this._mainDataProvider.getResourceObfOptions();

        for (const propName in obfOptions) {
            // add button attr data-prefil-keyword to booking transaction

            if (propName?.indexOf('data-prefill') > -1 && obfOptions?.[propName]) {
                let keyword = '';

                keyword = propName.replace('data-prefill-', '');
                keyword = keyword.replaceAll('-', '_');
                itemsToPrefill.push(
                    {
                        keyword,
                        value: obfOptions[propName] === 'true' ? true : obfOptions[propName]
                    }
                )
            }
          }

        // * Get parent cookies.
        const parentCookies = await this._initConfigDataService
            .getParentCookies()
            .catch((err) => {
                //
            });

        // Set META fbclid if exist in parent cookies
        if (parentCookies?.hasOwnProperty('_fbc')) {
            itemsToPrefill.push(
                {
                    keyword: 'fbclid',
                    value: getMetaCookieId(parentCookies['_fbc'])
                }
            )
        }

        // Set Google gclid if exist in parent cookies
        if (parentCookies?.hasOwnProperty('_gcl_aw')) {
            itemsToPrefill.push(
                {
                    keyword: 'gclid',
                    value: getGoogleCookieId(parentCookies['_gcl_aw'])
                }
            )
        }

        // Set BING msclkid if exist in parent cookies
        if (parentCookies?.hasOwnProperty('_uetmsclkid')) {
            itemsToPrefill.push(
                {
                    keyword: 'msclkid',
                    value: getBingCookieId(parentCookies['_uetmsclkid'])
                }
            )
        }

        for (var param in urlParams) {
            if (urlParams.hasOwnProperty(param)) {
                if (param.indexOf('utp') > -1 && param.indexOf('[') > -1 && param.indexOf(']') > -1) {
                    itemsToPrefill.push(
                        {
                            keyword: param.substring(param.indexOf("[") + 1, param.lastIndexOf("]")),
                            value: urlParams[param]
                        }
                    )
                }

                if (param.indexOf('utm_') > -1) {
                    itemsToPrefill.push(
                        {
                            keyword: param,
                            value: urlParams[param]
                        }
                    )
                }

                if (param.indexOf('fs_item_prefill') > -1 && param.indexOf('[') > -1 && param.indexOf(']') > -1) {
                    itemsToPrefill.push(
                        {
                            keyword: param.substring(param.indexOf("[") + 1, param.lastIndexOf("]")),
                            value: urlParams[param]
                        }
                    )
                }
            }
        }

        if (itemsToPrefill?.length && data.service) {
            data.service.choice_items_prefill = [];
            itemsToPrefill.forEach((item) => {
                data.service.choice_items_prefill.push({
                    keyword: item.keyword,
                    value: item.value
                })
            })
        }    

        this.getXrmConnection();
        const loader = this._loaderService.showLoader();

        data.source = {
            id: obfOptions.campaign_id && !isNaN(+obfOptions.campaign_id) ? +obfOptions.campaign_id : null,
            phone: obfOptions.phone || '',
            domain_url: obfOptions.main_url || '',
            abbr: obfOptions.campaign_abbr || null,
        };

        if (!data.voucher && obfOptions.voucher) {
            data.voucher = obfOptions.voucher;
        }

        // manual noPrice
        if (obfOptions.free_quote) {
            data.price = {
                type: 'no_price',
            };

            this._orderData.noPrice.next(true);

            // reset after used once
            obfOptions.free_quote = null;
        } else {
            this._orderData.noPrice.next(false);
        }

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.createTransaction(data, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);

                    const userData = this._userDataService.getUserData();

                    const dataLayerObjectUserInfo = {
                        event: 'user_info',
                        version: 'GA4',
                        user_id: userData?.id,
                        membership: userData ? userData?.membership ? 'member' : 'non-member' : ''
                    };
                    this._eventTrackingService.push(dataLayerObjectUserInfo);
                }),
            )
            .subscribe(
                (response: any) => {
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (response && !response.error) {
                        success = true;

                        this._postMessageService.sendState('booking-transaction-state', {
                            id: response.data.id,
                            service: {
                                title: response.data.service.title,
                            },
                            state: 'new',
                        });
            
                        this._orderData.addNewBooking(response.data);

                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        const obfOptions = this._mainDataProvider.getResourceObfOptions();
                    }

                    obfOptions.default_service_id = null;
                    obfOptions.postcode = null;
                    obfOptions.voucher = null;

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        // don't create anonymous transaction on pro booking
                        if (this._orderData.bookingType !== 'procrossbooking') {
                            this.checkForDisabledAccounts()
                                .then(
                                    () => { // resolve
                                        callback(validationResult, response);
    
                                    },
                                    () => { // reject
                                        callback(validationResult, response);
                                    }
                                );
                        } else {
                            callback(validationResult, response);
                        }
                    }

                    subscription.unsubscribe();
                },
                (response) => {
                    obfOptions.default_service_id = null;
                    obfOptions.postcode = null;
                    obfOptions.voucher = null;

                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
            );

        return;
    }

    /**
 * Get booking by booking id and set the bookingType base on obfOptions.action
 * @param bookingId
 * @param queryParams
 */
    public editBooking(
        bookingId: string | number,
        reqConfig?: RequestConfig,
    ): Promise<ServerResponse<Booking>> {
        return new Promise((res, rej) => {
            const requestConfig: RequestConfig = {
                queryParams: {
                    expand: ['all.all.all.all'],
                },
                validate: true,
            };

            Object.assign(requestConfig, reqConfig);

            this.getXrmConnection();
            const loader = this._loaderService.showLoader();

            const subscription =
                from(this.xrmConnection.createTransaction({ booking_id: bookingId }, requestConfig.queryParams))
                    .pipe(
                        finalize(() => {
                            this._loaderService.hideLoader(loader);
                        }),
                    )
                    .subscribe(
                        (serverResponse: ServerResponse<Booking>) => {
                            if (reqConfig.validate) {
                                serverResponse.validationCallback = this._errorHandlingService.validateResponse(serverResponse);
                            }

                            if (serverResponse && !serverResponse.error) {

                                const obfOptions = this._mainDataProvider.getResourceObfOptions();

                                this._orderData.bookingType = obfOptions.action;

                                if (typeof serverResponse.data.service !== 'number') {
                                    this._postMessageService.sendState('booking-transaction-state', {
                                        id: serverResponse.data.id,
                                        service: {
                                            title: serverResponse.data.service.title,
                                        },
                                        state: obfOptions.action,
                                    });
                                } else {
                                    this._postMessageService.sendState('booking-transaction-state', {
                                        id: serverResponse.data.id,
                                        service: {
                                            title: '',
                                        },
                                        state: obfOptions.action,
                                    });
                                }

                                this._orderData.addNewBooking(serverResponse.data);
                                res(serverResponse);
                            } else {
                                rej(serverResponse);
                            }

                            subscription.unsubscribe();
                        },
                        (error: ServerResponse<Booking>) => {
                            if (reqConfig.validate) {
                                error.validationCallback = this._errorHandlingService.validateResponse(error);
                            }

                            rej(error);
                            subscription.unsubscribe();
                        },
                    );
        });
    }

    /**
    * Get booking by booking id and set the bookingType base on obfOptions.action
    * @param bookingId
    * @param queryParams
    */
    public rebookBooking(
        bookingId: string | number,
        reqConfig?: RequestConfig,
    ): Promise<ServerResponse<Booking>> {
        return new Promise((res, rej) => {
            const requestConfig: RequestConfig = {
                queryParams: {
                    expand: ['all.all.all.all'],
                },
                validate: true,
            };

            Object.assign(requestConfig, reqConfig);

            this.getXrmConnection();
            const loader = this._loaderService.showLoader();

            const subscription =
                from(this.xrmConnection.createTransaction({ copied_booking_id: bookingId }, requestConfig.queryParams))
                    .pipe(
                        finalize(() => {
                            this._loaderService.hideLoader(loader);
                        }),
                    )
                    .subscribe(
                        (serverResponse: ServerResponse<Booking>) => {
                            if (reqConfig.validate) {
                                serverResponse.validationCallback = this._errorHandlingService.validateResponse(serverResponse);
                            }

                            if (serverResponse && !serverResponse.error) {

                                const obfOptions = this._mainDataProvider.getResourceObfOptions();

                                this._orderData.bookingType = 'rebook';

                                if (typeof serverResponse.data.service !== 'number') {
                                    this._postMessageService.sendState('booking-transaction-state', {
                                        id: serverResponse.data.id,
                                        service: {
                                            title: serverResponse.data.service.title,
                                        },
                                        state: 'rebook',
                                    });
                                } else {
                                    this._postMessageService.sendState('booking-transaction-state', {
                                        id: serverResponse.data.id,
                                        service: {
                                            title: '',
                                        },
                                        state: 'rebook',
                                    });
                                }

                                this._orderData.addNewBooking(serverResponse.data);
                                res(serverResponse);
                            } else {
                                rej(serverResponse);
                            }

                            subscription.unsubscribe();
                        },
                        (error: ServerResponse<Booking>) => {
                            if (reqConfig.validate) {
                                error.validationCallback = this._errorHandlingService.validateResponse(error);
                            }

                            rej(error);
                            subscription.unsubscribe();
                        },
                    );
        });
    }

    /**
     *
     * @param transactionId
     * @param reqConfig
     */
    public restoreTransaction(transactionId, reqConfig?: RequestConfig): Promise<ServerResponse<Booking>> {
        return new Promise((res, rej) => {
            const requestConfig: RequestConfig = {
                queryParams: {
                    expand: ['all.all.all.all'],
                },
                validate: true,
            };

            Object.assign(requestConfig, reqConfig);

            // secure we have xrmConnection.
            this.getXrmConnection();

            const loader = this._loaderService.showLoader('Restore your booking');

            const obfOptions = this._mainDataProvider.getResourceObfOptions();

            const sendData: { [key: string]: any } = {
                source: {
                    phone: obfOptions.phone || '',
                    domain_url: obfOptions.main_url || '',
                },
            };

            if (obfOptions.voucher) {
                sendData.voucher = obfOptions.voucher;
                obfOptions.voucher = null;
            }

            if (obfOptions.free_quote) {
                sendData.price = {
                    type: 'no_price',
                };

                this._orderData.noPrice.next(true);
                obfOptions.free_quote = null;
            } else {
                this._orderData.noPrice.next(false);
            }

            const subscription = from(this.xrmConnection.updateTransaction(sendData, transactionId, requestConfig.queryParams)).pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            ).subscribe(
                (serverResponse: ServerResponse<Booking>) => {

                    if (requestConfig.validate) {
                        serverResponse.validationCallback = this._errorHandlingService.validateResponse(serverResponse);
                    }

                    if (serverResponse && !serverResponse.error) {

                        this._orderData.bookingType = 'restore';
                        this._orderData.addNewBooking(serverResponse.data);

                        // Push to site booking-transaction-state
                        if (typeof serverResponse.data.service !== 'number') {
                            this._postMessageService.sendState('booking-transaction-state', {
                                id: serverResponse.data.id,
                                service: {
                                    title: serverResponse?.data?.service?.title,
                                },
                                state: 'restore',
                            });
                        } else {
                            this._postMessageService.sendState('booking-transaction-state', {
                                id: serverResponse.data.id,
                                service: {
                                    title: '',
                                },
                                state: 'restore',
                            });
                        }

                        obfOptions.default_service_id = null;
                        obfOptions.postcode = null;
                        obfOptions.voucher = null;
                        obfOptions.booking_transaction_id = null;

                        res(serverResponse);
                    } else {
                        rej(serverResponse);
                    }

                    subscription.unsubscribe();
                },
                (error: ServerResponse<Booking>) => {
                    if (requestConfig.validate) {
                        error.validationCallback = this._errorHandlingService.validateResponse(error);
                    }
                    rej(error);
                    subscription.unsubscribe();
                },
            );
        });
    }

    /**
     *
     * @param crossId
     * @param crossToken
     * @param serviceId
     * @param postcode
     * @param reqConfig
     */
    public proCrossSellTransaction(
        crossId: number,
        crossToken: string,
        serviceId: string | number,
        postcode: string,
        reqConfig?: RequestConfig,
    ): Promise<ServerResponse<Booking> | ServerResponse<Service>> {
        return new Promise(async (res, rej) => {
            const requestConfig: RequestConfig = {
                queryParams: {
                    expand: ['all.all.all.all'],
                },
                validate: true,
            };

            Object.assign(requestConfig, reqConfig);

            this.getXrmConnection();

            const loader = this._loaderService.showLoader();
            let serviceServerResponse: ServerResponse<Service>;
            serviceServerResponse = (
                await this._servicesService.fetchService(serviceId, { validate: false })
                    .catch((error: ServerResponse<Service>) => {
                        rej(error);
                    })
            ) as ServerResponse<Service>;

            // if we have error on fetch service stop the function execution.
            if (!serviceServerResponse) {
                return;
            }

            const serviceData = serviceServerResponse.data;
            const serviceChoices = (serviceData.choices || []) as Choice[];

            if (serviceChoices && serviceChoices.length) {
                // Insert postcode in address choice item
                serviceChoices.map((choice) => {
                    if (choice.positions.indexOf(ChoicesPositions.Init) !== -1) {
                        if (choice && choice.choice_items && choice.choice_items.length) {
                            choice.choice_items.map((choiceItem) => {
                                if (choiceItem.type === 'address') {
                                    choiceItem.value = {
                                        postcode,
                                    };
                                }
                            });
                        }
                    }
                });
            }

            const obfOptions = this._mainDataProvider.getResourceObfOptions();

            const sendData: { [key: string]: any } = {
                cross_id: crossId,
                cross_token: crossToken,
                service: {
                    id: serviceId,
                    choices: simplifyChoices(serviceChoices),
                },
                source: {
                    phone: obfOptions.phone || '',
                    domain_url: obfOptions.main_url || '',
                },
                voucher: obfOptions.voucher || null,
            };

            if (obfOptions.free_quote) {
                sendData.price = {
                    type: 'no_price',
                };

                this._orderData.noPrice.next(true);

                // reset after used once
                obfOptions.free_quote = null;
            }

            const subscription = from(this.xrmConnection.createTransaction(sendData, requestConfig.queryParams))
                .pipe(
                    finalize(() => {
                        this._loaderService.hideLoader(loader);
                    }),
                )
                .subscribe(
                    (serverResponse: ServerResponse<Booking>) => {
                        if (serverResponse && !serverResponse.error) {

                            // # console.log(typeof callback);
                            this._orderData.addNewBooking(serverResponse.data);

                            this._orderData.bookingType = 'procrossbooking';

                            // Push to site booking-transaction-state
                            if (typeof serverResponse.data.service !== 'number') {
                                this._postMessageService.sendState('booking-transaction-state', {
                                    id: serverResponse.data.id,
                                    service: {
                                        title: serverResponse?.data?.service?.title,
                                    },
                                    state: 'procrossbooking',
                                });
                            } else {
                                this._postMessageService.sendState('booking-transaction-state', {
                                    id: serverResponse.data.id,
                                    service: {
                                        title: '',
                                    },
                                    state: 'procrossbooking',
                                });
                            }

                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            obfOptions.default_service_id = null;
                            obfOptions.postcode = null;
                            obfOptions.voucher = null;
                            res(serverResponse);
                        } else {
                            res(serverResponse);
                        }

                        subscription.unsubscribe();
                    },
                    (error: ServerResponse<Booking>) => {
                        rej(error);
                        subscription.unsubscribe();
                    },
                );
        });
    }

    /**
     * Update transaction
     * response data is update in orderData and push updateBooking in bookingTransaction
     * in the end function call the callback passing the response
     * @param {string} transactionId
     * @param {Object} data
     * @param {Function} callback
     */
    public updateTransaction(transactionId, data, queryParams?: SDKQueryParams, callback?: Function, showInvisibleLoader: boolean = false) {
        
        this.getXrmConnection();

        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg16'), true, showInvisibleLoader);

        if (!data.voucher && this._mainDataProvider.getResourceObfOptions().voucher) {
            data.voucher = this._mainDataProvider.getResourceObfOptions().voucher;
        }

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.updateTransaction(data, transactionId, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);

                    // Prevent bug with hidden navigation bar when submit form with enter on mobile keyboard
                    if (window?.document?.querySelector('.obf-main-step-navigation')) {
                        const elementNavigation = window.document.querySelector('.obf-main-step-navigation');
                        if (elementNavigation.classList.contains('refresh')) {
                            elementNavigation.classList.remove('refresh');
                        }
                    }
                }),
            )
            .subscribe(
                (response: any) => {
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (response && response.data) {
                        success = true;

                        const bookingUpdated = this._orderData.updateBooking(response.data.id, response.data);

                        const obfOptions = this._mainDataProvider.getResourceObfOptions();
                        obfOptions.voucher = null;
                    }

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response: any) => {

                    // Provide updateBooking method cause it's specific with different request payload every time
                    const errorHandlerDepMethods: Array<DependencyMethod> = [
                        {
                            name: 'updateTransaction',
                            call: this.updateTransaction.bind(this),
                            requestParams: {
                                data,
                                transactionId,
                                queryParams,
                            },
                        },
                    ];

                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response, { localDepMethods: errorHandlerDepMethods, skipPositionCheckForModals: false });

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    // Not covered postcode on update booking, show error popup only
                    if (response?.error && response?.error.length && (response.error[0].code === 6714 || response.error[0].code === 6736)) {
                        const modalData = {
                            message: response.error[0].message ? response.error[0].message : this._localizationProvider.getLocalizedText(
                                'modalDefaultTitle'
                            ),
                            buttons: [
                                {
                                    label: this._localizationProvider.getLocalizedText(
                                        'modalDefaultConfirmButtonTitle'
                                    ),
                                    closeOnClick: true,
                                    type: 'primary',
                                    state: 'default',
                                    size: 'normal',
                                },
                            ]
                        };
                        this._modalsService.open('custom', modalData);
                    }

                    subscription.unsubscribe();
                },
            );

        return;
    }

    /**
     * Get leave reasons
     * @param queryParams
     * @param useCache
     * @param callback
     */
    public getLeaveReasons(queryParams?: SDKQueryParams, useCache: boolean = false, callback?: Function): Promise<any> {
        this.getXrmConnection();

        const loader = this._loaderService.showLoader();

        return new Promise((resolve: Function, reject: Function) => {
            let leaveReasons: any = this.getLeaveReasonsConfig();

            if (leaveReasons) {
                if (typeof callback === 'function') {
                    callback(leaveReasons);
                }

                resolve(leaveReasons);

                this._loaderService.hideLoader(loader);
            } else if (useCache && this._cacheService.checkCache('LeaveReasons', this.apiPaths.leaveReasons)) { // Check for cache
                leaveReasons = this._cacheService.getCache('LeaveReasons');

                this._configurationService.getConfiguration()
                    .then(
                        (configuration) => {
                            const mappedLeaveReasons = this.mapLeaveReasons(configuration, leaveReasons.data);

                            this._configurationDataProvider.setLeaveReasons(mappedLeaveReasons);

                            if (typeof callback === 'function') {
                                callback(mappedLeaveReasons);
                            }

                            resolve(mappedLeaveReasons);

                            this._loaderService.hideLoader(loader);
                        },
                        () => {
                            // error
                        },
                    ).catch((error) => {
                        // catch error
                    });

            } else {
                this.xrmConnection.getLeaveReasons(queryParams)
                    .then(
                        (response: any) => { // Success
                            if (response.statusCode === 200) {
                                if (response.decoder) {
                                    // Error
                                    if (response.decoder.error) {
                                        if (typeof callback === 'function') {
                                            callback(response.data);
                                        }

                                        reject({ message: 'Fail get leave reasons: ' + response.decoder.error, fatal: true });
                                    } else { // Success

                                        this._configurationService.getConfiguration()
                                            .then(
                                                (configuration) => {
                                                    const leaveReasons = this.mapLeaveReasons(configuration, response.data);

                                                    // Set response in cache if cache is turned on
                                                    if (useCache) {
                                                        this._cacheService.setCache('LeaveReasons', response.data, 1, this.apiPaths.leaveReasons);
                                                    }

                                                    // Set data in provider
                                                    this._configurationDataProvider.setLeaveReasons(leaveReasons);

                                                    if (typeof callback === 'function') {
                                                        callback(response);
                                                    }

                                                    resolve(leaveReasons);
                                                },
                                                () => {

                                                },
                                            );

                                    }
                                }

                                this._loaderService.hideLoader(loader);
                            } else {
                                this._loaderService.hideLoader(loader);

                                if (typeof callback === 'function') {
                                    callback(response);
                                }

                                reject({ message: 'Fail get leave reasons', fatal: true });
                            }
                        },
                        (response: any) => { // Error
                            this._loaderService.hideLoader(loader);

                            if (typeof callback === 'function') {
                                callback(response);
                            }

                            reject({ message: 'Fail get leave reasons', fatal: true });
                        },
                    ).catch((error) => {
                        this._loaderService.hideLoader(loader);

                        if (typeof callback === 'function') {
                            callback(error);
                        }

                        reject({ message: 'Catch: Fail get leave reasons', fatal: true });
                    });
            }
        });
    }

    public mapLeaveReasons(configuration, leaveReasons) {
        const leaveReasonsConfig: any = this._configurationService.getPropFromConfig('leave_reasons_modal');
        
        if (leaveReasonsConfig && leaveReasons) {
            leaveReasonsConfig.map((leaveReasonModal) => {

                leaveReasonModal.leave_reasons = [];

                leaveReasons.map((reason) => {
                    if (reason.positions.indexOf(leaveReasonModal.position) !== -1) {
                        leaveReasonModal.leave_reasons.push(reason);
                    }
                });

                return leaveReasonModal;
            });

            return leaveReasonsConfig;

        } else {
            const errorModel = {
                reason: 'Bad configuration data',
                message: 'Fail build leaveModals profile_layout_config is missing',
            },
                reportSubject = 'Wrong Layout Config';

            // this._errorReportingService.report(
            //     {
            //         emailErrors: [errorModel]
            //     },
            //     false,
            //     reportSubject
            // );

            return new Array(); // * send empty array for login modals to fix errors when we dont have configurate leave modals
        }
    }

    /**
     * Retrieve validations
     */
    public getLeaveReasonsConfig(): any {
        return this._configurationDataProvider.getLeaveReasons();
    }

    public submitLeaveReason(bookingId: string, data: any, queryParams?: SDKQueryParams, callback?: Function) {
        const loader = this._loaderService.showLoader();
        const subscription = from(this.xrmConnection.submitLeaveReason(data, bookingId, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        callback(response);
                    }

                    subscription.unsubscribe();
                },
                (response) => {
                    this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        callback(response);
                    }

                    subscription.unsubscribe();
                },
            );

        return;
    }

    /**
     * After the response is returned will call the callback function
     * @param {string} transactionId
     * @param {string} fromDate start day
     * @param {number} limitTo count of days to return
     * @param {Function} callback The call back function
     */
    public fetchAvailability(transactionId: string, choiceItemId: number, fromDate: string, limitTo: number, callback?): void {
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg17'), true, false),
            toDate: string = moment(fromDate)
                .locale('en')
                .add(limitTo, 'days')
                .format('YYYY-MM-DD'),
            queryParams: SDKQueryParams = {
                'query[transaction_id]': transactionId,
                'query[choice_item_id]': choiceItemId,
                'query[from_date]': fromDate,
                'query[to_date]': toDate,
            };

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.getAvailability(queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => { // Success
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);
                    
                    if (!response.error) success = true;                    
                    
                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response) => { // Error
                    if (typeof callback === 'function') {
                        const validationCallback: Function | null = this._errorHandlingService.validateResponse(response),
                            validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }
                },
            );
    }

    /**
     * Find and return recored address in booking transaction
     */
    public getTransactionAddress(): any {
        const transaction = this._orderData.activeBooking.getValue()?.get(),
            transactionServiceChoices = transaction.service.choices;

        // If service has no choices
        if (!transactionServiceChoices || !transactionServiceChoices.length) {
            return false;
        }

        for (let index = 0; index < transactionServiceChoices.length; index++) {
            const choice = transactionServiceChoices[index];
            if (choice.positions.indexOf(ChoicesPositions.Init) !== -1) {
                const addressChoiceItem = findChoiceItemByType(choice, 'address');

                if (addressChoiceItem) {
                    return Object.assign({}, addressChoiceItem.value);
                }
            }
        }

        return false;
    }

    /**
     * Return transaction phones if there's any
     */
    public getTransactionPhones(): Phone | boolean {
        const transaction = this._orderData.activeBooking.getValue()?.get(),
            transactionPhones = transaction.phones && transaction.phones.length ? transaction.phones : false;

        return transactionPhones;
    }

    public getTransaction() {
        return this._orderData.activeBooking?.getValue()?.get();
    }

    public getTransactionType() {
        return this._orderData.bookingType;
    }

    /**
     * Fetch service choices by position and type
     * @param {string | null} choicePosition
     * @param {string} choiceType
     */
    public getServiceChoices(choicePosition?: string | null, choiceType?: string): Array<Choice> {
        if (!this._orderData.activeBooking.getValue()) {
            return new Array();
        }

        const transaction = this._orderData.activeBooking.getValue()?.get();

        if (transaction && transaction.service && transaction.service.choices) {
            const filteredChoices: Array<Choice> = transaction.service.choices.filter((choice) => {

                if (choicePosition) {
                    if (choiceType) {
                        return choice.positions && choice.positions.indexOf(choicePosition) !== -1 && choice.type === choiceType;
                    } else {
                        return choice.positions && choice.positions.indexOf(choicePosition) !== -1;
                    }
                } else if (choiceType) {

                    return choice.type === choiceType;
                } else {
                    return 1;
                }
            });

            return filteredChoices;
        }

        return new Array();
    }

    /**
     * Fetch price from price breakdown by type
     * @param {string} priceType
     */
    public getTransactionPrice(priceType: string): any {
        const transaction = this._orderData.activeBooking.getValue() ? this._orderData.activeBooking.getValue().get() : null;

        if (transaction && transaction.price && transaction.price.price_breakdown) {
            for (let index = 0; index < transaction.price.price_breakdown.length; index++) {
                const price = transaction.price.price_breakdown[index];
                if (price.type === priceType) {
                    return Object.assign({}, price);
                }
            }
        }

        return null;
    }

    /**
     * Requests challenge token for 3DS payment
     * @param queryParams
     */
    public getChallengeToken(queryParams?: SDKQueryParams): Promise<any> {
        const transaction = this._orderData.activeBooking.getValue()?.get();

        return new Promise((resolve, reject) => {
            const subscription = from(this.xrmConnection.requestChallengeToken(transaction.id, queryParams))
                .subscribe(
                    (response: any) => { // Success
                        if (!response.error) {
                            resolve(response);
                        } else {
                            reject(response);
                        }

                        subscription.unsubscribe();
                    },
                    (response) => { // Error
                        this._errorHandlingService.validateResponse(response);

                        reject(response);

                        subscription.unsubscribe();
                    },
                );
        });
    }

    /**
     * Check user paymethod for security requirements
     * @param data paymethod data
     * @param queryParams
     */
    public checkPaymethodSecurityRequirements(data: any, queryParams?: SDKQueryParams): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscription = from(this.SDKClientsService.checkPaymethodSecurityRequirements(data, queryParams))
                .subscribe(
                    (response: any) => { // Success
                        if (!response.error) {
                            resolve(response);
                        } else {
                            reject(response);
                        }

                        subscription.unsubscribe();

                    },
                    (response) => { // Error
                        this._errorHandlingService.validateResponse(response);

                        subscription.unsubscribe();

                        reject(response);
                    },
                );
        });
    }

    /**
     * Check LeaveReasons data status
     * @param {Boolean} checkInCache Flag which decides whether to check for LeaveReasons in cache or not
     * @returns {Object}
     */
    public getLeaveReasonsStatus(checkInCache: Boolean): Promise<{ status: String, processingMethod: Function }> {
        const leaveReasonsStatusData: { status: String, processingMethod: Function } = {
            status: 'missing',
            processingMethod: (response: any, useCache: boolean): Promise<any> => {
                return new Promise((resolve: Function, reject: Function) => {
                    if (response.decoder && response.decoder.error) {
                        // Error
                        reject({ message: 'Failed to get leave reasons: ' + response.error, fatal: true });
                    } else {
                        this._configurationService.getConfiguration()
                            .then(
                                (configuration) => {
                                    const leaveReasons = this.mapLeaveReasons(configuration, response.data);

                                    // Set response in cache if cache is turned on
                                    if (useCache) {
                                        this._cacheService.setCache('LeaveReasons', response.data, 1, this.apiPaths.leaveReasons);
                                    }

                                    // Set data in provider
                                    this._configurationDataProvider.setLeaveReasons(leaveReasons);

                                    resolve();
                                },
                                (error) => {
                                    reject(error);
                                },
                            );
                    }
                });
            },
        };

        // Bind current class scope to the method
        leaveReasonsStatusData.processingMethod.bind(this);

        return new Promise((resolve: Function, reject: Function) => {
            let leaveReasons: any = this.getLeaveReasonsConfig();

            if (leaveReasons) {
                leaveReasonsStatusData.status = 'cached';

                // return leaveReasonsStatusData;
                resolve(leaveReasonsStatusData);
            } else if (checkInCache && this._cacheService.checkCache('LeaveReasons', this.apiPaths.leaveReasons)) { // Check for cache
                leaveReasons = this._cacheService.getCache('LeaveReasons');

                this._configurationService.getConfiguration()
                    .then(
                        (configuration) => {
                            const mappedLeaveReasons = this.mapLeaveReasons(configuration, leaveReasons.data);

                            this._configurationDataProvider.setLeaveReasons(mappedLeaveReasons);

                            leaveReasonsStatusData.status = 'cached';

                            // return leaveReasonsStatusData;
                            resolve(leaveReasonsStatusData);
                        },
                        (error) => {
                            // Error
                            reject(error);
                        },
                    ).catch((error) => {
                        // catch error
                        reject(error);
                    });

            } else {
                // return leaveReasonsStatusData;
                resolve(leaveReasonsStatusData);
            }
        });

    }

    /**
     * We only use it to tag the booking we dont care if fail ot success.
     */
    public appendTags(transactionId, data: { tags: string[] }, queryParams?: SDKQueryParams, callback?: Function) {

        this.getXrmConnection();
        
        this.xrmConnection.appendTags(data, transactionId, queryParams).then(
            (successResponse) => {
                // We dont care for the success
            },
        ).catch((error) => {
            // We dont care if whe have error.
        });
    }

    /**
     * Requests payment methods available for this transaction
     * @param queryParams
     * @param callback
     */
    public getPaymentMethods(queryParams?: SDKQueryParams, callback?: Function): void {
        const transaction = this._orderData.activeBooking.getValue()?.get(),
            loader = this._loaderService.showLoader();

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.getPaymentMethods(transaction.id, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => { // Success
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (!response.error) {
                        success = true;
                    }

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response) => { // Error
                    this._errorHandlingService.validateResponse(response);

                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
            );
    }

    /**
     * Requests setup for specific paymethod
     * @param data
     * @param queryParams
     * @param callback
     */
    public getPaymethodSetup(data: any, queryParams?: SDKQueryParams, callback?: Function): void {
        const transaction = this._orderData.activeBooking.getValue()?.get(),
            loader = this._loaderService.showLoader();

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.getPaymethodSetup(data, transaction.id, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => { // Success
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (!response.error) {
                        success = true;
                    }

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response) => { // Error
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                }
            );
    }

    /**
     * Initiates final checkout for current transaction
     * @param data
     * @param queryParams
     */
    public initiateCheckout(transactionId, data, queryParams?: SDKQueryParams, callback?: Function, showInvisibleLoader: boolean = false) {
        this.getXrmConnection();
        const loader = this._loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg16'), true, showInvisibleLoader);

        let success: Boolean = false;

        const subscription = from(this.xrmConnection.initiateCheckout(data, transactionId, queryParams))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (response && response.data) {
                        success = true;
                        const bookingUpdated = this._orderData.updateBooking(response.data.id, response.data);

                        const obfOptions = this._mainDataProvider.getResourceObfOptions();
                        obfOptions.voucher = null;

                        this._postMessageService.sendState('booking-transaction-state', {
                            id: response.data.id,
                            service: {
                                title: response.data.service.title,
                            },
                            state: 'complete',
                        });
                    }

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response: any) => {

                    // Provide updateBooking method cause it's specific with different request payload every time
                    const errorHandlerDepMethods: Array<DependencyMethod> = [
                        {
                            name: 'updateTransaction',
                            call: this.updateTransaction.bind(this),
                            requestParams: {
                                data,
                                transactionId,
                                queryParams,
                            },
                        },
                    ];

                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response, { localDepMethods: errorHandlerDepMethods, skipPositionCheckForModals: false });

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
            );

        return;
    }

    /**
     * Extract choice item value from booking transaction by ID
     *
     * @param {array} choiceItems
     * @param {number} choiceItemId
     */
    public extractChoiceItemValue(choiceItems: Array<ChoiceItem>, choiceItemId: number) {
        let choiceItemValue: any = null;

        for (let choiceItemIndex = 0; choiceItemIndex < choiceItems.length; choiceItemIndex++) {
            const currentChoiceItem = choiceItems[choiceItemIndex];

            if (currentChoiceItem.id === choiceItemId) {
                choiceItemValue = currentChoiceItem.value;

                return choiceItemValue;
            }

            // Choice item has nested choice items
            if (currentChoiceItem.choice_items) {
                const childChoiceItemValue: any = this.extractChoiceItemValue(currentChoiceItem.choice_items, choiceItemId);

                if (childChoiceItemValue) {
                    return childChoiceItemValue;
                }
            }
        }

        return choiceItemValue;
    }

    /**
     * Check choice item value
     *
     * @param {object} choiceItem
     */
    public isChoiceItemEmpty(choiceItemValue: any) {
        let isEmpty = false;

        switch (typeof choiceItemValue) {
            case 'number':
                isEmpty = choiceItemValue === 0;

                break;
            case 'object':
                isEmpty = Object.keys(choiceItemValue).length === 0;

                break;
            case 'string':
                isEmpty = choiceItemValue === '';

                break;
            default:
                isEmpty = false;

                break;
        }

        return isEmpty;
    }

    /**
     * Send final data layer push for this substep when we click back
     */
    public RemoveCrossSaleDatalayer(removedCrossSales): void {
        const CrossSellsDataLayer = new Array();

        if (removedCrossSales && removedCrossSales.length) {
            for (let index = 0; index < removedCrossSales.length; index++) {
                const crossSell = removedCrossSales[index];
                if (crossSell.value === 0) {
                    const formattedCrossSell = {};

                    Object.assign(formattedCrossSell, {
                        id: crossSell.id,
                        serviceName: crossSell.title,
                        price: crossSell.display_price,
                        description: crossSell.description ? crossSell.description : '',
                    });

                    CrossSellsDataLayer.push(formattedCrossSell);
                }
            }
        }

        // Datalayer push
        this._eventTrackingService.push({
            event: 'cross-sale-remove-data',
            successfullyRemovedCrossSales: CrossSellsDataLayer.length ? CrossSellsDataLayer : '',
        });
    }

    /**
     * Checks service in transaction for flag, which indicates that we will work with anonymous registration
     */
    public checkForDisabledAccounts(): Promise<void> {
        return new Promise(async (resolve: Function, reject: Function) => {
            if (this._userDataService.isUserLogged() || (this._userDataService.getUserData() && this._userDataService.getUserData().type_id === 1)) {
                resolve();
            } else {
                this._authenticationService.anonymousRegister({ type_id: 1 }, null, async (response) => {
                    resolve();
                });
            }

            // const authRestrictions = await this._userDataService.authRestrictions();

            // if (authRestrictions === "anonym_auth" || authRestrictions === "not_required_auth") {
            // } else {
            //     resolve();
            // }
        });
    }

    /**
     * Retrieve promocode update subscruption
     * @returns
     */
    public getPromocodeUpdateSubscription(): BehaviorSubject<Boolean> {
        return this.promocodeUpdate;
    }

    /**
     * Emit new value to promocode update behavior subject
     * @param isActionApply boolean
     */
    public emitPromocodeUpdate(isActionApply: Boolean): void {
        this.promocodeUpdate.next(isActionApply);
    }

    public sendReportDetails(data: any, callback?: Function): void {
        const loader = this._loaderService.showLoader();

        let success: Boolean = false;

        const subscription = from(this.SDKSystemService.sendCoverageReportDetails(data))
            .pipe(
                finalize(() => {
                    this._loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => { // Success
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (!response.error) {
                        success = true;
                    }

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                },
                (response) => { // Error
                    const validationCallback: Function | null = this._errorHandlingService.validateResponse(response);

                    if (typeof callback === 'function') {
                        const validationResult: any = {
                            success,
                        };

                        if (validationCallback) {
                            Object.assign(validationResult, { callbackMethod: validationCallback });
                        }

                        callback(validationResult, response);
                    }

                    subscription.unsubscribe();
                }
            );
    }
}
