import { AdditionalEnvironmentConfig } from '../../../../app/models/environment.model';

export const additional: AdditionalEnvironmentConfig = {
    // set path to logo.
    logos: {
        brandLogo: '',
        memberLogo: './membership-default-logo.png',
        avatarLogo: './basic-profile.svg',
        brandName: ''
    },
    // to enable(true) and disable(false) functionality 
    functionality: {
        xrmAuth: false,
        keepAnonymousSession: true,
        disableMembershipTerms: true,
        disablePromocodeField: false,
        addToCalendar: false,
        googleMapsAutoComplete: false,
        registerForm: {
            birthdateFieldEnabled: false, // enable/disable birthdate field in register form
            referralFieldEnabled: true
        },
        showRedirectPopup: false,
        showNotificationPreference: false,
        sidebarChatEnabled: true,
        disableMostPopularInSearch: true,
        disableFingerprint: false,
        singleDomainWidget: false,
        automated_by_site_url: 'https://www.serviceos.com/?smm=2&utm_source={CLIENT}&utm_medium=OBS&utm_campaign={CLIENT}',
        phonePreferredCountries: []
    },
    concierge: {
        email: null,
        formattedPhoneNumber: null,
        phoneNumber: null
    },
    claimAccount: {
        isActive: false
    },
    defaultBanners: [
        {
            // eslint-disable-next-line max-len
            initBanner: '{"id":1,"do_not_rewrite":true,"visible":false,"publishing_condition":{"from_date":"2019-11-20","to_date":null,"device_type":"all","client_type":["all"],"type":"0"},"show_on":{"position":["init"],"section":["sidebar","body","thank_you","cross_sale"]},"content":{"title":"Book a service for your <b>home</b>, <b>office</b> or <b>garden</b>","sub_title":"in less than 30 seconds","body":"","image_url":"./time.png","tc_text":""}}'
        }
    ],
    benefitsConfig: {
        background_url: "",
        title: "",
        subtitle: "",
        items: [
            {
                image_url: './benefit-n-1.png',
                title: 'Flexible Work Hours',
                description: 'Make a booking all week long, even for weekends and official holidays.'
            },
            {
                image_url: './benefit-n-2.png',
                title: 'Secure Payment Methods',
                description: 'Pay easily online by card.'
            },
            {
                image_url: './benefit-n-3.png',
                title: 'Helpful Customer Support',
                description: 'Hey, let\'s talk! Either give us a call or start a live chat with an operator.'
            },
            {
                image_url: './benefit-n-4.png',
                title: 'Satisfaction Guarantee',
                description: 'Each job is completed in accordance to the highest professional standards.'
            }
        ]
    },
    additionalChoices: [
        {
            id: 0,
            title: '',
            description: '',
            sort: 50,
            summary_title: 'Extras',
            required: true,
            customize: null,
            type: 'default',
            choice_items: [
                {
                    id: 0,
                    parent_id: 0,
                    title: 'User Details ',
                    description: '',
                    sort: 50,
                    type: 'contact_details',
                    value: [],
                    duration: 0,
                    summary_title: 'details',
                    customize: {
                    },
                    required: false,
                    display_price: '',
                    choice_items: null,
                    payload: null,
                    image_url: null,
                    min_value: 0,
                    max_value: 0,
                    is_in_summary: true
                },
            ],
            positions: [
                'on_summary'
            ]
        }
    ]
};
